@charset "UTF-8";
.field--label-inline .field__label, .field--label-inline .field__items {
  float: left; }

@font-face {
  font-family: 'FuturaPT-Book';
  src: url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_0_0.eot');
  src: url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_0_0.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_0_0.woff2') format('woff2'), url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_0_0.woff') format('woff'), url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_0_0.ttf') format('truetype'); }

@font-face {
  font-family: 'FuturaPT-Light';
  src: url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_1_0.eot');
  src: url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_1_0.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_1_0.woff2') format('woff2'), url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_1_0.woff') format('woff'), url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_1_0.ttf') format('truetype'); }

@font-face {
  font-family: 'FuturaPT-Bold';
  src: url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_2_0.eot');
  src: url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_2_0.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_2_0.woff2') format('woff2'), url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_2_0.woff') format('woff'), url('/sites/all/themes/custom/greenpan15/fonts/2E39EA_2_0.ttf') format('truetype'); }

html {
  font-family: sans-serif; }
  html.fonts-loaded {
    font-family: 'FuturaPT-Light', sans-serif; }
    html.fonts-loaded h1 {
      font-family: 'FuturaPT-Light'; }

.nav-burger.label:before {
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20viewBox%3D%220%200%2042%2020%22%20enable-background%3D%22new%200%200%2042%2020%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%3E%20%3Cg%3E%20%20%3Cpath%20fill%3D%22%23E31B23%22%20d%3D%22M41%2C2H1C0.4%2C2%2C0%2C1.6%2C0%2C1s0.4-1%2C1-1h40c0.6%2C0%2C1%2C0.4%2C1%2C1S41.6%2C2%2C41%2C2z%22%2F%3E%20%3C%2Fg%3E%20%3Cg%3E%20%20%3Cpath%20fill%3D%22%23E31B23%22%20d%3D%22M41%2C11H1c-0.6%2C0-1-0.4-1-1s0.4-1%2C1-1h40c0.6%2C0%2C1%2C0.4%2C1%2C1S41.6%2C11%2C41%2C11z%22%2F%3E%20%3C%2Fg%3E%20%3Cg%3E%20%20%3Cpath%20fill%3D%22%23E31B23%22%20d%3D%22M41%2C20H1c-0.6%2C0-1-0.4-1-1s0.4-1%2C1-1h40c0.6%2C0%2C1%2C0.4%2C1%2C1S41.6%2C20%2C41%2C20z%22%2F%3E%20%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-size: contain; }

.nav-burger.label {
  width: 44px;
  height: 44px;
  position: absolute;
  z-index: 999;
  right: 0; }
  .nav-burger.label:before {
    background: none;
    z-index: 999; }
  .nav-burger.label .bar {
    background-color: #333;
    display: block;
    width: 32px;
    height: 1px;
    border-radius: 100px;
    position: relative;
    top: 22px;
    right: 0;
    transition: all 0.5s; }
    .nav-burger.label .bar:first-child {
      -webkit-transform: translateY(-8px);
      -ms-transform: translateY(-8px);
      transform: translateY(-8px); }
    .nav-burger.label .bar:last-child {
      -webkit-transform: translateY(8px);
      -ms-transform: translateY(8px);
      transform: translateY(8px); }

.nav-burger.label.x .bar {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }
  .nav-burger.label.x .bar:first-child {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .nav-burger.label.x .bar:last-child {
    opacity: 0; }

.nav-burger.checkbox ~ .l-region--navigation .menu-level-1 {
  margin-right: -50%;
  opacity: 0;
  height: 0; }
  @media all and (min-width: 64em) {
    .nav-burger.checkbox ~ .l-region--navigation .menu-level-1 {
      margin-right: 0;
      opacity: 1;
      height: 100%; } }

@media (max-width: 1024px) {
  .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 {
    width: 50%;
    background-color: #333;
    height: 100%;
    float: right;
    display: block;
    margin-right: 0;
    opacity: 1;
    transition: margin-right 0.5s ease-in, opacity 0.1s;
    position: fixed;
    padding-top: 50px;
    right: 0;
    top: 50px; }
    .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul {
      margin: 0;
      width: 100%;
      opacity: 1;
      display: block; }
      .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li {
        display: block;
        height: auto;
        position: relative; }
        .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a {
          font-size: 16px;
          text-transform: uppercase;
          height: auto;
          padding: 1em 2em;
          margin: 0;
          border: 0;
          color: #fff;
          width: 100%;
          transition: height 0.3s, margin 0.3s; }
          .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a:after, .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a:before {
            border-right: 2px solid;
            content: '';
            display: block;
            height: 8px;
            margin-top: -9px;
            position: absolute;
            -webkit-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: rotate(135deg);
            left: 1em;
            top: 50%;
            width: 0; }
          .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a:after {
            margin-top: -4px;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg); }
          .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a.active, .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a:hover, .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a:focus {
            color: #85a312; } }

.nav-burger.label:before {
  content: '';
  width: 42px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer; }

input.nav-burger {
  display: none; }

@media  (min-width: 75em) {
  .nav-burger.label {
    display: none; } }

@media (min-width: 1025px) {
  .l-header.shrink .nav-burger {
    opacity: 0; }
  .l-header.shrink .nav-burger.checkbox:checked ~ .l-region--navigation {
    position: absolute;
    width: 100%;
    height: 300px;
    top: 50px;
    background: none;
    z-index: -1; }
    .l-header.shrink .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 {
      width: 30%;
      background-color: #333;
      height: 100%;
      float: right;
      display: block;
      margin-right: 0;
      opacity: 1;
      transition: margin-right 0.5s ease-in, opacity 0.1s; }
      .l-header.shrink .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul {
        margin: 0;
        width: 100%;
        opacity: 1;
        display: block; }
        .l-header.shrink .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li {
          display: block;
          height: auto;
          position: relative; }
          .l-header.shrink .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a {
            font-size: 16px;
            text-transform: uppercase;
            height: auto;
            padding: 1em 2em;
            margin: 0;
            border: 0;
            color: #fff;
            width: 100%;
            transition: height 0.3s, margin 0.3s; }
            .l-header.shrink .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a:after, .l-header.shrink .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a:before {
              border-right: 2px solid;
              content: '';
              display: block;
              height: 8px;
              margin-top: -6px;
              position: absolute;
              -webkit-transform: rotate(135deg);
              -ms-transform: rotate(135deg);
              transform: rotate(135deg);
              left: 1em;
              top: 50%;
              width: 0; }
            .l-header.shrink .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a:after {
              margin-top: -2px;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg); }
            .l-header.shrink .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a.active, .l-header.shrink .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a:hover, .l-header.shrink .nav-burger.checkbox:checked ~ .l-region--navigation .menu-level-1 ul li a:focus {
              color: #85a312; } }

.slider-container {
  overflow: hidden;
  position: relative;
  width: 100%; }

.slider-container ul li.views-row {
  float: left;
  width: 100%;
  position: relative; }
  .slider-container ul li.views-row .node__title {
    display: none; }

ul.arrow-nav {
  display: none;
  text-align: center;
  clear: both;
  font-size: 10px; }
  ul.arrow-nav li {
    display: inline;
    margin: 5px;
    cursor: pointer; }

.page-node-16 .field--name-field-page-paragraphs:first-child {
  background: none; }

.swipe-arrow .swipe-next, .swipe-arrow .swipe-prev {
  width: 30px;
  height: 51px;
  background-size: contain;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  cursor: pointer; }
.swipe-arrow .swipe-next {
  background-image: url('/sites/all/themes/custom/greenpan15/img/arrow.png');
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  right: 0; }
.swipe-arrow .swipe-prev {
  background-image: url('/sites/all/themes/custom/greenpan15/img/arrow.png');
  left: 0; }

.page-node-16 .field--name-field-page-paragraphs, .page-node-16 .field--name-field-coll-key-features, .page-node-16 .field--name-field-coll-essentials, .section-collection .field--name-field-page-paragraphs, .section-collection .field--name-field-coll-key-features, .section-collection .field--name-field-coll-essentials {
  position: relative; }
  .page-node-16 .field--name-field-page-paragraphs .field__item, .page-node-16 .field--name-field-coll-key-features .field__item, .page-node-16 .field--name-field-coll-essentials .field__item, .section-collection .field--name-field-page-paragraphs .field__item, .section-collection .field--name-field-coll-key-features .field__item, .section-collection .field--name-field-coll-essentials .field__item {
    float: left;
    display: inline-block;
    position: relative;
    width: 100%; }
.page-node-16 .field--name-field-coll-essentials .taxonomy-term__content img, .section-collection .field--name-field-coll-essentials .taxonomy-term__content img {
  width: 60px; }

.paragraphs-items-field-tabs-slides ul.top_nav, .field--name-field-coll-essentials ul.top_nav {
  display: none; }
  @media  (min-width: 48em) {
    .paragraphs-items-field-tabs-slides ul.top_nav, .field--name-field-coll-essentials ul.top_nav {
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center; } }
  .paragraphs-items-field-tabs-slides ul.top_nav li, .field--name-field-coll-essentials ul.top_nav li {
    display: inline-block;
    width: 25%;
    border-right: 1px solid #fff;
    background-color: #828282;
    color: #fff;
    float: left;
    text-transform: uppercase;
    padding: 20px 0;
    cursor: pointer;
    font-size: 12px; }
    @media  (min-width: 64em) {
      .paragraphs-items-field-tabs-slides ul.top_nav li, .field--name-field-coll-essentials ul.top_nav li {
        font-size: 16px; } }
    .paragraphs-items-field-tabs-slides ul.top_nav li.active, .field--name-field-coll-essentials ul.top_nav li.active {
      background-color: #333; }
    .paragraphs-items-field-tabs-slides ul.top_nav li:last-of-type, .field--name-field-coll-essentials ul.top_nav li:last-of-type {
      border-right: 0; }
  .paragraphs-items-field-tabs-slides ul.top_nav.slides-2 li, .field--name-field-coll-essentials ul.top_nav.slides-2 li {
    width: 50%; }
  .paragraphs-items-field-tabs-slides ul.top_nav.slides-3 li, .field--name-field-coll-essentials ul.top_nav.slides-3 li {
    width: 33.33%; }
.paragraphs-items-field-tabs-slides ul.arrow-nav, .field--name-field-coll-essentials ul.arrow-nav {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: block; }
  .paragraphs-items-field-tabs-slides ul.arrow-nav li, .field--name-field-coll-essentials ul.arrow-nav li {
    width: 8px;
    margin: 0 5px;
    height: 8px;
    font-size: 0;
    display: inline-block; }
    .paragraphs-items-field-tabs-slides ul.arrow-nav li span, .field--name-field-coll-essentials ul.arrow-nav li span {
      background-color: #333;
      color: #fff;
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 50%; }
    .paragraphs-items-field-tabs-slides ul.arrow-nav li.active span, .field--name-field-coll-essentials ul.arrow-nav li.active span {
      background-color: #fff; }
.paragraphs-items-field-tabs-slides .slider-container, .field--name-field-coll-essentials .slider-container {
  position: relative;
  width: 100%;
  margin-bottom: 40px; }
  .paragraphs-items-field-tabs-slides .slider-container > .field__items > .field__item, .field--name-field-coll-essentials .slider-container > .field__items > .field__item {
    float: left;
    width: 100%;
    position: relative; }
@media  (min-width: 48em) {
  .paragraphs-items-field-tabs-slides .paragraphs-item-slide, .paragraphs-items-field-tabs-slides .field--name-field-coll-ess-image, .field--name-field-coll-essentials .paragraphs-item-slide, .field--name-field-coll-essentials .field--name-field-coll-ess-image {
    margin-top: 63px; } }
.paragraphs-items-field-tabs-slides .paragraphs-item-slide .field--name-field-paragraph-image, .paragraphs-items-field-tabs-slides .field--name-field-coll-ess-image .field--name-field-paragraph-image, .field--name-field-coll-essentials .paragraphs-item-slide .field--name-field-paragraph-image, .field--name-field-coll-essentials .field--name-field-coll-ess-image .field--name-field-paragraph-image {
  position: relative;
  width: 100%; }
  .paragraphs-items-field-tabs-slides .paragraphs-item-slide .field--name-field-paragraph-image img, .paragraphs-items-field-tabs-slides .field--name-field-coll-ess-image .field--name-field-paragraph-image img, .field--name-field-coll-essentials .paragraphs-item-slide .field--name-field-paragraph-image img, .field--name-field-coll-essentials .field--name-field-coll-ess-image .field--name-field-paragraph-image img {
    vertical-align: bottom;
    width: 100%; }
.paragraphs-items-field-tabs-slides .paragraphs-item-slide .field--name-field-paragraph-text, .paragraphs-items-field-tabs-slides .paragraphs-item-slide .field--name-field-paragraph-title, .paragraphs-items-field-tabs-slides .field--name-field-coll-ess-image .field--name-field-paragraph-text, .paragraphs-items-field-tabs-slides .field--name-field-coll-ess-image .field--name-field-paragraph-title, .field--name-field-coll-essentials .paragraphs-item-slide .field--name-field-paragraph-text, .field--name-field-coll-essentials .paragraphs-item-slide .field--name-field-paragraph-title, .field--name-field-coll-essentials .field--name-field-coll-ess-image .field--name-field-paragraph-text, .field--name-field-coll-essentials .field--name-field-coll-ess-image .field--name-field-paragraph-title {
  position: absolute;
  right: 85px;
  z-index: 100;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.93);
  padding: 15px; }
.paragraphs-items-field-tabs-slides .paragraphs-item-slide .field--name-field-paragraph-text, .paragraphs-items-field-tabs-slides .field--name-field-coll-ess-image .field--name-field-paragraph-text, .field--name-field-coll-essentials .paragraphs-item-slide .field--name-field-paragraph-text, .field--name-field-coll-essentials .field--name-field-coll-ess-image .field--name-field-paragraph-text {
  top: 91px; }
  .paragraphs-items-field-tabs-slides .paragraphs-item-slide .field--name-field-paragraph-text p, .paragraphs-items-field-tabs-slides .field--name-field-coll-ess-image .field--name-field-paragraph-text p, .field--name-field-coll-essentials .paragraphs-item-slide .field--name-field-paragraph-text p, .field--name-field-coll-essentials .field--name-field-coll-ess-image .field--name-field-paragraph-text p {
    margin-bottom: 18px; }
.paragraphs-items-field-tabs-slides .paragraphs-item-slide .field--name-field-paragraph-title, .paragraphs-items-field-tabs-slides .field--name-field-coll-ess-image .field--name-field-paragraph-title, .field--name-field-coll-essentials .paragraphs-item-slide .field--name-field-paragraph-title, .field--name-field-coll-essentials .field--name-field-coll-ess-image .field--name-field-paragraph-title {
  font-size: 22px;
  font-family: 'FuturaPT-Bold'; }
  @media  (min-width: 64em) {
    .paragraphs-items-field-tabs-slides .paragraphs-item-slide .field--name-field-paragraph-title, .paragraphs-items-field-tabs-slides .field--name-field-coll-ess-image .field--name-field-paragraph-title, .field--name-field-coll-essentials .paragraphs-item-slide .field--name-field-paragraph-title, .field--name-field-coll-essentials .field--name-field-coll-ess-image .field--name-field-paragraph-title {
      top: 33px;
      left: initial; } }

.view-range ul li {
  display: inline-block;
  position: relative;
  float: left;
  text-align: center; }
  .view-range ul li img {
    width: 80%; }
.view-range .slider-container {
  position: relative; }
.view-range .swipe-arrow {
  width: 100%;
  position: absolute;
  top: 50%; }

.field--name-field-coll-essentials .slider-container {
  margin-bottom: 0;
  padding-bottom: 60px; }
  @media  (min-width: 48em) {
    .field--name-field-coll-essentials .slider-container {
      margin-bottom: 40px;
      padding-bottom: 0; } }
  .field--name-field-coll-essentials .slider-container .taxonomy-term--essentials {
    padding: 15px; }
    @media  (min-width: 48em) {
      .field--name-field-coll-essentials .slider-container .taxonomy-term--essentials {
        padding: 0; } }

@media (min-width: 1025px) {
  .swipe-arrow .swipe-next, .swipe-arrow .swipe-prev {
    display: block; }
  .views-exposed-widget {
    width: 33%; } }

@media (max-width: 1024px) {
  .slider-container ul li.views-row article {
    padding: 0; }
  .page-node-16 .field--name-field-paragraph-text, .page-node-16 .field--name-field-paragraph-title {
    position: relative;
    right: 0;
    width: auto;
    top: 0; }
  .page-node-16 .field--name-field-paragraph-title {
    top: 0;
    min-height: 60px; }
  .page-node-16 .field--name-field-paragraph-text > .field__items > .field__item {
    padding-bottom: 40px; }
  .paragraphs-items-field-tabs-slides .paragraphs-item-slide .field--name-field-paragraph-text {
    padding-top: 90px; } }

@media (max-width: 1020px) {
  .swipe-arrow, .swipe-arrow .swipe-next, .swipe-arrow .swipe-prev {
    display: none; } }

.go-back {
  position: fixed;
  background-color: #333;
  bottom: 0;
  left: 0;
  font-size: 1.1rem;
  width: 45px; }
  .go-back:hover {
    background-color: #474747; }
  .go-back a {
    color: #fff;
    padding: 10px;
    display: block; }

* {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none; }

a {
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0), transparent; }
  a:focus, a:active {
    outline: none; }

html {
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.1);
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

body {
  font-family: FuturaPT-Light;
  font-weight: normal;
  font-style: normal;
  color: #333;
  margin: 0;
  padding: 0;
  overflow-x: hidden; }

::-moz-selection {
  background: #85a312;
  color: #fff; }

::selection {
  background: #85a312;
  color: #fff; }

.l-footer, .l-main, .l-header {
  max-width: none; }

#block-views-homepage-blocks-block, .paragraphs-item-text-with-image, .paragraphs-item-buttonbar, .view-collections-induction, .field--name-field-page-paragraphs, .view-collections, .view-store-locator, .view-range, .paragraphs-item-tabs, .section-collection .field--name-field-coll-essentials, .section-collection .field--name-field-coll-features-benefits {
  max-width: 1300px;
  margin: 0 auto;
  display: block; }

.section-collection .field--name-field-coll-specifications {
  max-width: 1300px;
  display: block; }

.l-footer {
  width: 100%;
  margin-bottom: 0; }

.l-region--navigation {
  clear: none; }

.l-region--header {
  margin-top: 92px; }

.l-main {
  min-height: 100%;
  margin-bottom: -120px;
  width: 100%;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto; }
  .l-main:after {
    height: 300px;
    content: '';
    display: block; }

.element-invisible, .breadcrumb, #block-system-main-menu .block__title, .field-group-format-toggler {
  display: none; }

h1 {
  color: #85a312; }

p {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  color: #333; }

b, strong {
  font-family: FuturaPT-Bold;
  font-weight: normal; }

body.section-collection.safari .field--name-field-coll-image img {
  margin-bottom: -5px; }

input {
  outline: none; }

.section-user .l-main, .section-users .l-main {
  margin-top: 300px; }

.section-magneto .group-footer, .section-3d-cookware .group-footer, .section-privacy-policy .group-footer {
  display: none; }

.section-privacy-policy, .section-contact-us {
  background-color: #f5f5f5; }

@media  (min-width: 48em) {
  .two-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; } }

.extra-page-elements-wrapper h2 {
  display: none; }

.field-paragraph-link a {
  font-family: 'FuturaPT-Bold';
  display: inline-block;
  margin: 20px 0;
  font-size: 18px;
  color: #333; }

.views-submit-button a, .views-submit-button .form-submit, .node-readmore a, .node-readmore .form-submit {
  text-transform: uppercase;
  padding: 15px 30px;
  background-color: #333;
  color: #fff;
  font-family: 'FuturaPT-Bold';
  margin: 20px auto;
  display: block;
  border-radius: 2px; }
  @media  (min-width: 48em) {
    .views-submit-button a, .views-submit-button .form-submit, .node-readmore a, .node-readmore .form-submit {
      margin: 40px auto; } }
  .views-submit-button a:hover, .views-submit-button .form-submit:hover, .node-readmore a:hover, .node-readmore .form-submit:hover {
    background-color: #85a312; }

.l-region--header .field--name-description-field, .block--ds-extras-header-image-term .field--name-description-field, .field--name-field-page-intro {
  padding: 25px 15px;
  background-color: #f0f0f0;
  margin-bottom: 15px;
  text-align: center; }
  @media  (min-width: 48em) {
    .l-region--header .field--name-description-field, .block--ds-extras-header-image-term .field--name-description-field, .field--name-field-page-intro {
      padding: 50px 15px;
      margin-bottom: 40px; } }
  .l-region--header .field--name-description-field p, .block--ds-extras-header-image-term .field--name-description-field p, .field--name-field-page-intro p {
    font-size: 16px;
    line-height: 32px; }
    @media  (min-width: 48em) {
      .l-region--header .field--name-description-field p, .block--ds-extras-header-image-term .field--name-description-field p, .field--name-field-page-intro p {
        font-size: 22px; } }
  .l-region--header .field--name-description-field .field__item, .block--ds-extras-header-image-term .field--name-description-field .field__item, .field--name-field-page-intro .field__item {
    max-width: 768px;
    margin: auto; }

.node-readmore, .node__title {
  text-align: center; }

.node__title a {
  color: #333; }

.l-main {
  padding: 0; }
  @media  (min-width: 48em) {
    .l-main {
      padding: 0; } }

@media (max-width: 1024px) {
  .l-main {
    width: 100%; }
  .l-region--header {
    margin-top: 50px;
    overflow: hidden; }
  .menu {
    float: none; }
  .field--name-field-page-intro p {
    line-height: normal; }
  .field--name-field-title-banner {
    height: 100%;
    top: 0; }
    .field--name-field-title-banner .field__item {
      width: 100%;
      text-align: center;
      top: 50%;
      left: 50%;
      bottom: inherit;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .field--name-field-title-banner .field__item p {
      font-size: 18px; }
  .node--page.view-mode-full .field--name-field-paragraph-title, .node--page.view-mode-full .field--name-field-paragraph-text, .node--page.view-mode-full .field--name-field-paragraph-image {
    width: 100%;
    float: none;
    display: block;
    margin: 0;
    right: 0; }
  .node--page.view-mode-full .field--name-field-paragraph-image {
    text-align: center; }
  .node--page.view-mode-full .field--name-field-page-paragraphs .field__item {
    display: block;
    padding: 15px; }
  .node--page.view-mode-full .field--name-field-paragraph-text {
    top: initial;
    position: initial;
    margin-top: 10px;
    left: 0; } }

.field--name-field-sc-page-content a {
  color: #85a312; }

.navbar-bar .navbar-icon-createcontent.navbar-active:before {
  background-image: url("../svg/filewhite.svg"); }
.navbar-bar .navbar-icon-createcontent:active:before, .navbar-bar .navbar-icon-createcontent:before {
  background-image: url("../svg/filegray.svg"); }
.navbar-bar .navbar-icon-configuration.navbar-active:before {
  background-image: url("../svg/wrenchwhite.svg"); }
.navbar-bar .navbar-icon-configuration:active:before, .navbar-bar .navbar-icon-configuration:before {
  background-image: url("../svg/wrenchgray.svg"); }

.sticker {
  padding: 0;
  float: left;
  width: 25%;
  margin-left: 15px;
  transition: all 0.3s ease-in-out; }
  @media  (min-width: 85em) {
    .sticker {
      margin-left: 0; } }
  .sticker img {
    width: 100%;
    transition: all 0.3s ease-in-out; }

.stickerfade {
  width: 25%;
  margin-left: 15px; }
  @media  (min-width: 48em) {
    .stickerfade {
      position: relative; } }
  @media  (min-width: 85em) {
    .stickerfade {
      margin-left: 0; } }
  .stickerfade img {
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: all 0.3s ease-in-out; }

.field--name-field-page-header-image {
  overflow: hidden; }

.field--name-field-page-header-image img {
  vertical-align: bottom;
  min-height: 250px;
  max-width: 1200px;
  width: auto;
  max-height: none;
  overflow: hidden; }
  @media  (min-width: 85em) {
    .field--name-field-page-header-image img {
      max-width: none; } }
  @media  (min-width: 64em) {
    .field--name-field-page-header-image img {
      width: 100%; } }

.l-region--header .block__title {
  display: none; }
.l-region--header .block--greenpan-sitewide-greenpan-sticker-header {
  max-width: 1300px;
  margin: 0 auto; }

.l-header {
  height: 92px;
  z-index: 300;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  margin: auto;
  position: fixed;
  background-color: #fff;
  transition: all 0.3s ease-in; }

@media  (min-width: 64em) {
  .l-header-wrapper {
    width: 100%;
    max-width: 1300px;
    margin: auto;
    position: relative; } }

.block__content .field--name-title .field__item h2 {
  font-size: 18px; }

.group-left, .group-right {
  width: 50%;
  display: inline-block; }

.l-header.shrink {
  height: 50px; }
  .l-header.shrink .l-region--header-extra {
    display: none; }
  .l-header.shrink .sticker {
    width: 200px; }
  .l-header.shrink .nav-burger.label {
    display: block;
    opacity: 1;
    cursor: pointer; }
  .l-header.shrink .menu {
    opacity: 0;
    display: none; }

.l-header .menu {
  float: right;
  margin: 0;
  display: none; }
  @media (min-width: 1025px) {
    .l-header .menu {
      display: block; } }
  .l-header .menu .leaf {
    list-style-type: none;
    display: inline-block;
    list-style-image: none; }
    .l-header .menu .leaf a {
      font-size: 16px;
      height: 35px;
      margin-top: 35px;
      margin-left: 1em;
      margin-right: 1em;
      padding: 0;
      color: #333;
      text-decoration: none;
      white-space: nowrap;
      text-transform: uppercase;
      display: inline-block; }
      .l-header .menu .leaf a:hover, .l-header .menu .leaf a a:focus, .l-header .menu .leaf a.active-trail {
        border-bottom: 2px solid #78a22e; }

.field--name-field-pb-header-image img {
  vertical-align: bottom;
  max-height: none;
  overflow: hidden;
  min-width: 100%;
  height: auto;
  width: auto;
  min-height: 280px;
  max-width: 1200px;
  right: 50%;
  position: relative; }
  @media  (min-width: 26em) {
    .field--name-field-pb-header-image img {
      right: inherit; } }
  @media  (min-width: 48em) {
    .field--name-field-pb-header-image img {
      max-width: none;
      width: 100%;
      min-height: inherit; } }
  @media  (min-width: 64em) {
    .field--name-field-pb-header-image img {
      max-width: 1200px;
      width: auto;
      min-height: 280px; } }

.content, .block__content {
  position: relative; }
  .content .field--name-field-coll-title-detail-page, .content .field--name-field-pb-header-title, .content .field--name-field-header-title, .block__content .field--name-field-coll-title-detail-page, .block__content .field--name-field-pb-header-title, .block__content .field--name-field-header-title {
    text-align: center;
    font-size: 26px;
    color: #333;
    background: rgba(255, 255, 255, 0.93);
    line-height: 1.1em;
    font-family: FuturaPT-Light;
    position: relative;
    width: 100%; }
    @media  (min-width: 48em) {
      .content .field--name-field-coll-title-detail-page, .content .field--name-field-pb-header-title, .content .field--name-field-header-title, .block__content .field--name-field-coll-title-detail-page, .block__content .field--name-field-pb-header-title, .block__content .field--name-field-header-title {
        width: auto;
        position: absolute;
        right: 50px;
        bottom: 50px;
        top: auto;
        left: auto;
        -webkit-transform: inherit;
        -ms-transform: inherit;
        transform: inherit;
        margin-right: 0;
        text-align: left; } }
    .content .field--name-field-coll-title-detail-page .field__item h2, .content .field--name-field-coll-title-detail-page .field__item p, .content .field--name-field-pb-header-title .field__item h2, .content .field--name-field-pb-header-title .field__item p, .content .field--name-field-header-title .field__item h2, .content .field--name-field-header-title .field__item p, .block__content .field--name-field-coll-title-detail-page .field__item h2, .block__content .field--name-field-coll-title-detail-page .field__item p, .block__content .field--name-field-pb-header-title .field__item h2, .block__content .field--name-field-pb-header-title .field__item p, .block__content .field--name-field-header-title .field__item h2, .block__content .field--name-field-header-title .field__item p {
      margin: 0;
      font-weight: normal;
      padding: 20px; }
      @media  (min-width: 64em) {
        .content .field--name-field-coll-title-detail-page .field__item h2, .content .field--name-field-coll-title-detail-page .field__item p, .content .field--name-field-pb-header-title .field__item h2, .content .field--name-field-pb-header-title .field__item p, .content .field--name-field-header-title .field__item h2, .content .field--name-field-header-title .field__item p, .block__content .field--name-field-coll-title-detail-page .field__item h2, .block__content .field--name-field-coll-title-detail-page .field__item p, .block__content .field--name-field-pb-header-title .field__item h2, .block__content .field--name-field-pb-header-title .field__item p, .block__content .field--name-field-header-title .field__item h2, .block__content .field--name-field-header-title .field__item p {
          font-size: 1.7rem;
          line-height: 1.3em;
          padding: 1em 1.6em; } }

@media (min-width: 1025px) {
  .l-header-wrapper {
    width: 100%;
    max-width: 1300px;
    margin: auto;
    position: relative; } }

@media (max-width: 1024px) {
  .stickerfade img, .sticker img {
    width: 160px; }
  .l-header {
    height: 50px; }
  .group-header .field-group-format-wrapper {
    position: relative; }
  .block__content .field--name-field-header-title {
    line-height: 1em;
    padding: 0; } }

.user-login {
  padding: 15px;
  max-width: 1300px;
  margin: auto; }

.l-footer {
  clear: both;
  position: relative;
  min-height: 300px;
  background: #85a312;
  color: #fff;
  font-weight: 200;
  font-size: 14px;
  text-align: center; }
  .l-footer .field {
    z-index: 40;
    position: relative; }
  .l-footer .block--greenpan-sitewide-greenpan-sticker-footer .block__title {
    display: none; }
  .l-footer .block--greenpan-sitewide-greenpan-sticker-footer .sticker-footer--icons {
    overflow: hidden;
    *zoom: 1;
    position: absolute;
    top: 0;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .l-footer .block--greenpan-sitewide-greenpan-sticker-footer .sticker-footer--icons a {
      width: 45px;
      height: 45px;
      display: block;
      float: left;
      margin: 0px;
      cursor: default; }
  .l-footer .block--greenpan-sitewide-greenpan-sticker-footer .sticker-footer--text {
    padding-top: 27px;
    background-image: url(../img/healthy_ceramic_footer.jpg);
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 125px; }
    .l-footer .block--greenpan-sitewide-greenpan-sticker-footer .sticker-footer--text p {
      font-size: 2.2rem;
      line-height: 2.2rem;
      text-transform: uppercase;
      color: #fff;
      font-family: 'FuturaPT-Bold';
      text-shadow: 1px 1px #5a5a5a; }
      @media  (min-width: 48em) {
        .l-footer .block--greenpan-sitewide-greenpan-sticker-footer .sticker-footer--text p {
          font-size: 2.8rem;
          line-height: 2.8rem; } }
  .l-footer .menu {
    margin: 0 auto;
    display: inline-block; }
    .l-footer .menu li {
      float: left;
      position: relative;
      padding: 0 15px;
      display: block;
      list-style-image: none; }
      .l-footer .menu li.first {
        margin-left: -5px; }
      .l-footer .menu li:after {
        content: '|';
        position: absolute;
        right: -4px; }
      .l-footer .menu li.last:after {
        display: none; }
      .l-footer .menu li a {
        color: #fff; }
        .l-footer .menu li a:hover {
          color: #333; }
  .l-footer .field--name-field-page-footer-link .field__item {
    display: inline-block;
    position: relative; }
    .l-footer .field--name-field-page-footer-link .field__item:after {
      content: '|';
      margin: 0 5px; }

.block--menu-menu-footer .block__title {
  display: none; }

.l-region--footer2 {
  display: block;
  padding-bottom: 15px; }
  @media  (min-width: 26em) {
    .l-region--footer2 .block--menu-menu-footer, .l-region--footer2 .block--language-region {
      display: inline-block; } }
  .l-region--footer2 .block--language-region {
    position: relative;
    bottom: 5px; }
    .l-region--footer2 .block--language-region .block__title {
      display: none; }
    .l-region--footer2 .block--language-region .language-region-language-select-title, .l-region--footer2 .block--language-region .language-region-language-select-container {
      background: transparent; }
    .l-region--footer2 .block--language-region .language-region-language-select-container > ul {
      color: #333;
      font-weight: bold; }
    .l-region--footer2 .block--language-region .language-region-language-select-title {
      font-weight: 200;
      font-size: 14px; }
      .l-region--footer2 .block--language-region .language-region-language-select-title:after {
        content: '|';
        margin: 0 5px; }
    .l-region--footer2 .block--language-region .language-region-language-select {
      border: 0;
      min-width: 220px;
      border-radius: 3px;
      padding: 5px; }
    .l-region--footer2 .block--language-region li.language_region-language-select-continent {
      display: block;
      width: 100px;
      float: left;
      text-transform: uppercase;
      font-weight: bold; }
    .l-region--footer2 .block--language-region ul.language_region-language-select-continent {
      text-transform: none;
      font-weight: normal; }
    .l-region--footer2 .block--language-region li.language_region-language-select-region {
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 5px;
      margin-bottom: 5px; }
      .l-region--footer2 .block--language-region li.language_region-language-select-region:last-of-type {
        border-bottom: 0; }
    .l-region--footer2 .block--language-region ul.language_region-language-select-region li.language_region-language-select-language {
      border-bottom: 0; }
      .l-region--footer2 .block--language-region ul.language_region-language-select-region li.language_region-language-select-language:before {
        content: '-';
        padding-right: 3px;
        color: #85a312;
        position: relative;
        bottom: 2px; }
      .l-region--footer2 .block--language-region ul.language_region-language-select-region li.language_region-language-select-language a {
        color: #85a312; }
        .l-region--footer2 .block--language-region ul.language_region-language-select-region li.language_region-language-select-language a:hover {
          color: #333; }

@media (max-width: 26em) {
  .l-footer .menu li {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: center; }
    .l-footer .menu li.first {
      padding-left: 10px; }
    .l-footer .menu li:after {
      content: '';
      position: relative;
      right: inherit; } }

.site-name {
  float: left; }

.front .field--name-field-page-extra-blocks .odd {
  display: none;
  /*hack to avoid the testemonial block'*/ }

.front .l-content article {
  border-bottom: 2px solid #f3f3f3;
  position: relative; }
  .front .l-content article:last-of-type {
    border-bottom: 0; }
  .front .l-content article header {
    top: 20%; }
  .front .l-content article .field--name-field-product-intro {
    top: 40%; }
  .front .l-content article .links--inline {
    top: 80%; }
  .front .l-content article header, .front .l-content article .field--name-field-product-intro, .front .l-content article .links--inline {
    margin-right: -50%;
    left: 50%;
    position: absolute;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.field--name-field-page-header-image {
  line-height: 0; }
  .field--name-field-page-header-image img {
    height: auto;
    min-width: 100%; }

.field--name-field-title-banner {
  max-width: 1300px;
  margin: auto;
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  font-size: 26px; }
  .field--name-field-title-banner .field__item {
    position: absolute;
    right: 0;
    bottom: 40px;
    text-transform: uppercase;
    color: #333;
    background: rgba(255, 255, 255, 0.93);
    padding: 1em 1.6em; }
    .field--name-field-title-banner .field__item p {
      line-height: 1.4em;
      font-size: 26px;
      font-weight: 100; }

.section-about-greenpan .field--name-field-page-footer-title, .section-about-greenpan .field--name-field-page-footer-text p {
  color: #fff; }

.field--name-field-page-extra-blocks .views-row, .node--testimonial .node__content {
  overflow: hidden;
  *zoom: 1; }
  .field--name-field-page-extra-blocks .views-row .field--name-field-show-in-extra-blocks, .field--name-field-page-extra-blocks .views-row .field--name-field-testimonial-info, .field--name-field-page-extra-blocks .views-row .field--name-field-testimonial-intro, .field--name-field-page-extra-blocks .views-row .field--name-field-testimonial-picture, .node--testimonial .node__content .field--name-field-show-in-extra-blocks, .node--testimonial .node__content .field--name-field-testimonial-info, .node--testimonial .node__content .field--name-field-testimonial-intro, .node--testimonial .node__content .field--name-field-testimonial-picture {
    float: left; }
  .field--name-field-page-extra-blocks .views-row .field--name-field-testimonial-picture, .node--testimonial .node__content .field--name-field-testimonial-picture {
    width: 30%; }
  .field--name-field-page-extra-blocks .views-row .field--name-field-show-in-extra-blocks, .field--name-field-page-extra-blocks .views-row .field--name-field-testimonial-info, .field--name-field-page-extra-blocks .views-row .field--name-field-testimonial-intro, .node--testimonial .node__content .field--name-field-show-in-extra-blocks, .node--testimonial .node__content .field--name-field-testimonial-info, .node--testimonial .node__content .field--name-field-testimonial-intro {
    width: 70%;
    text-align: left;
    padding-left: 60px; }

.node-type-testimonial {
  padding-top: 100px; }
  .node-type-testimonial .node__content {
    margin-top: 65px; }
    .node-type-testimonial .node__content .field__label {
      display: none; }
    .node-type-testimonial .node__content .field--name-field-testimonial-info, .node-type-testimonial .node__content .field--name-field-testimonial-intro {
      margin-bottom: 35px; }

#block-views-testimonials-block {
  padding: 15px;
  position: relative;
  margin-top: 40px; }
  #block-views-testimonials-block h2.block__title {
    font-family: 'FuturaPT-Bold';
    text-align: center; }
  #block-views-testimonials-block .field--name-field-testimonial-picture {
    float: left;
    position: relative;
    height: 200px; }
    #block-views-testimonials-block .field--name-field-testimonial-picture .field__item {
      position: absolute;
      width: 200px;
      height: 200px;
      overflow: hidden;
      border-top-left-radius: 120px;
      border-top-right-radius: 120px;
      border-bottom-right-radius: 120px;
      border-bottom-left-radius: 120px;
      border-radius: 120px; }
  #block-views-testimonials-block img {
    position: absolute;
    z-index: 1;
    width: auto;
    height: 100%;
    max-width: none; }
  #block-views-testimonials-block .field--name-field-testimonial-intro {
    font-size: 20px; }
  #block-views-testimonials-block .field--name-field-testimonial-info p {
    font-style: italic;
    margin-top: 15px;
    font-size: 18px; }

@media  (min-width: 48em) {
  .field--name-field-page-footer-text {
    margin-bottom: 50px; } }

.field--name-field-page-footer-title, .field--name-field-page-footer-text p {
  font-size: 20px;
  line-height: 30px; }

.field--name-field-page-footer-title {
  display: block;
  padding-top: 25px;
  font-size: 2rem;
  margin-bottom: 20px; }

@media (max-width: 1024px) {
  #block-views-testimonials-block .field--name-field-testimonial-picture {
    text-align: center; }
  #block-views-testimonials-block img {
    position: relative; }
  #block-views-testimonials-block .field--name-field-testimonial-picture .field__item {
    left: 0;
    right: 0;
    margin: auto; }
  .view-testimonials .view-content {
    margin-top: 10px;
    padding-top: 0; } }

@media (max-width: 35em) {
  .field--name-field-page-footer-title, .field--name-field-page-footer-text p, .l-region--header .field--name-description-field p, .block--ds-extras-header-image-term .field--name-description-field p, .field--name-field-page-intro p, .view-collections .group-left, .view-collections .group-right {
    font-size: 16px;
    line-height: normal; } }

.paragraphs-items table thead tr td {
  font-family: 'FuturaPT-Bold';
  padding: 10px; }
.paragraphs-items tbody tr:nth-child(odd) {
  background-color: #f0f0f0; }
.paragraphs-items tbody tr td {
  text-align: center; }
.paragraphs-items tbody tr td:first-child {
  text-align: left; }
.paragraphs-items table img {
  height: 20px; }
.paragraphs-items table tbody tr td {
  padding: 20px;
  font-family: 'FuturaPT-Light';
  position: relative; }
.paragraphs-items table tr td:hover .tooltip {
  display: block; }
.paragraphs-items table tr td a {
  cursor: default; }
.paragraphs-items .tooltip {
  display: none;
  position: absolute;
  text-align: center;
  opacity: 0.9;
  width: auto;
  background-color: #000;
  color: #fff;
  padding: 12px;
  bottom: 100%;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 7px; }
  .paragraphs-items .tooltip:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #000 transparent transparent transparent;
    position: absolute;
    bottom: -10px;
    left: 45%; }
.paragraphs-items .stars {
  background: url('../img/stars.jpg') no-repeat center;
  background-size: 80px; }
.paragraphs-items .cross {
  background: url('../img/cross.png') no-repeat center;
  background-size: 20px; }
.paragraphs-items .check {
  background: url('../img/check.png') no-repeat center;
  background-size: 20px; }

.social {
  width: 200px;
  display: inline-block;
  margin-bottom: 40px; }
  .social li {
    cursor: pointer;
    margin: 0;
    float: left;
    width: 33.33%; }
    .social li a {
      display: block;
      width: 20px;
      height: 20px;
      text-indent: -9999px;
      transition: 0.3s; }
    .social li .icon {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      margin: 0 auto; }
    .social li .fb {
      background-image: url('/sites/all/themes/custom/greenpan15/svg/fb.svg'); }
    .social li .twit {
      background-image: url('/sites/all/themes/custom/greenpan15/svg/twit.svg'); }
    .social li .tmblr {
      background-image: url('/sites/all/themes/custom/greenpan15/svg/tmblr.svg'); }

/*Contact us socials*/
.block--views-footer-social-icons-block {
  margin-bottom: 40px; }
  .block--views-footer-social-icons-block .block__title {
    display: none; }

.field--name-field-page-social-icons {
  margin: 0 auto;
  width: 55%; }
  .field--name-field-page-social-icons .node--social-icon {
    width: 30px;
    margin: 3px;
    float: left; }
    .field--name-field-page-social-icons .node--social-icon a img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      -webkit-filter: gray;
      filter: gray;
      filter: url('data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/s….3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale'); }
    .field--name-field-page-social-icons .node--social-icon a:hover img {
      -webkit-filter: grayscale(0);
      filter: grayscale(0);
      -webkit-filter: none;
      filter: none;
      filter: url('data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/s….3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale'); }
  .field--name-field-page-social-icons .field__label {
    display: none; }
  .field--name-field-page-social-icons .node__title {
    display: none; }

.view-footer-social-icons {
  width: 200px;
  margin: 0 auto; }

.view-footer-social-icons .view-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .view-footer-social-icons .view-content .views-row, .view-footer-social-icons .view-content .field__item {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1; }
    .view-footer-social-icons .view-content .views-row .field--name-field-si-footer-image, .view-footer-social-icons .view-content .field__item .field--name-field-si-footer-image {
      width: 30px;
      height: 30px;
      display: inline-block; }
    .view-footer-social-icons .view-content .views-row .node__title, .view-footer-social-icons .view-content .field__item .node__title {
      display: none; }

.field-group-div {
  background-color: #f0f0f0;
  text-align: center;
  position: relative;
  padding: 40px 20px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto; }
  .field-group-div .field--name-field-footer-title {
    font-family: 'FuturaPT-Bold';
    color: #333;
    font-size: 1.3rem;
    margin: 20px 0; }
  .field-group-div .field--name-field-footer-link a {
    background-color: #333;
    padding: 15px 30px;
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 30px;
    border-radius: 2px; }
    .field-group-div .field--name-field-footer-link a:hover {
      background-color: #85a312; }

.view-collections-induction li.views-row, .view-collections li.views-row {
  width: 100%;
  padding: 15px;
  background-color: #f5f5f5;
  display: inline-block;
  margin-bottom: 0.5%;
  margin-top: 0.5%;
  vertical-align: top; }
  @media  (min-width: 48em) {
    .view-collections-induction li.views-row, .view-collections li.views-row {
      width: 49.5%;
      margin-right: -4px; } }
  @media  (min-width: 48em) {
    .view-collections-induction li.views-row.views-row-even, .view-collections li.views-row.views-row-even {
      margin-left: 1%; } }
  .view-collections-induction li.views-row .group-right h2, .view-collections li.views-row .group-right h2 {
    display: none; }
  .view-collections-induction li.views-row .see-collection-link, .view-collections li.views-row .see-collection-link {
    padding-top: 15px;
    font-family: FuturaPT-Bold;
    color: #333;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    display: block; }
    @media  (min-width: 48em) {
      .view-collections-induction li.views-row .see-collection-link, .view-collections li.views-row .see-collection-link {
        font-size: 1.1rem;
        text-align: left; } }
  .view-collections-induction li.views-row .see-collection-link, .view-collections-induction li.views-row .field--name-field-coll-essentials, .view-collections li.views-row .see-collection-link, .view-collections li.views-row .field--name-field-coll-essentials {
    width: 100%;
    margin: 0 auto; }
    @media  (min-width: 64em) {
      .view-collections-induction li.views-row .see-collection-link, .view-collections-induction li.views-row .field--name-field-coll-essentials, .view-collections li.views-row .see-collection-link, .view-collections li.views-row .field--name-field-coll-essentials {
        width: 50%;
        height: auto;
        min-height: 60px;
        float: left;
        display: inline-block; } }
.view-collections-induction .group-header .field--name-field-coll-title-overview-page, .view-collections .group-header .field--name-field-coll-title-overview-page {
  text-transform: uppercase;
  pointer-events: none; }
  .view-collections-induction .group-header .field--name-field-coll-title-overview-page strong, .view-collections .group-header .field--name-field-coll-title-overview-page strong {
    font-family: FuturaPT-Bold;
    font-weight: inherit; }
.view-collections-induction .group-left, .view-collections-induction .group-right, .view-collections .group-left, .view-collections .group-right {
  float: left;
  padding: 45px 15px 15px 15px;
  line-height: 28px; }
.view-collections-induction .group-right .field--name-field-coll-essentials, .view-collections .group-right .field--name-field-coll-essentials {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100px; }
.view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items, .view-collections .group-right > .field--name-field-coll-essentials > .field__items {
  float: right; }
  .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item {
    float: left;
    width: 50px;
    height: 50px;
    margin: 0 5px;
    position: relative; }
    @media  (min-width: 64em) {
      .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item {
        margin: 5px;
        width: 45px;
        height: 45px; } }
    .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item:hover .group-tooltip, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item:hover .group-tooltip {
      text-align: center;
      display: block;
      opacity: 0.9;
      position: absolute;
      width: 180px;
      height: auto;
      background-color: #000;
      padding: 12px;
      bottom: 125%;
      left: -65px;
      border-radius: 7px; }
      .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item:hover .group-tooltip:after, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item:hover .group-tooltip:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: #000 transparent transparent transparent;
        position: absolute;
        bottom: -10px;
        left: 45%; }
    .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip {
      display: none;
      opacity: 0; }
      .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-name-field, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-name-field {
        text-transform: uppercase;
        font-family: FuturaPT-Bold; }
      .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-name-field, .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-description-field p, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-name-field, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-description-field p {
        color: #fff; }
      .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-description-field p, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-description-field p {
        line-height: 20px; }
      .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-name-field .field__label, .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-description-field .field__label, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-name-field .field__label, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-description-field .field__label {
        display: none; }
      .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-name-field .field__item, .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-description-field .field__item, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-name-field .field__item, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item .group-tooltip .field--name-description-field .field__item {
        width: 100%;
        float: none;
        height: auto;
        line-height: 20px;
        margin: 0; }
    .view-collections-induction .group-right > .field--name-field-coll-essentials > .field__items > .field__item .taxonomy-term--essentials--collection-page-icon, .view-collections .group-right > .field--name-field-coll-essentials > .field__items > .field__item .taxonomy-term--essentials--collection-page-icon {
      overflow: hidden;
      *zoom: 1; }

.section-collection .field--name-field-coll-specifications {
  overflow: hidden;
  *zoom: 1;
  width: 90%;
  margin: 0 auto;
  margin-top: 40px; }
  @media  (min-width: 48em) {
    .section-collection .field--name-field-coll-specifications {
      width: 50%;
      margin-top: 80px; } }
  .section-collection .field--name-field-coll-specifications .field__label {
    display: none; }
  .section-collection .field--name-field-coll-specifications > .field__items {
    text-align: left; }
    @media  (min-width: 48em) {
      .section-collection .field--name-field-coll-specifications > .field__items {
        text-align: center; } }
    .section-collection .field--name-field-coll-specifications > .field__items > .field__item {
      width: 50%;
      margin: 0 -4px;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 20px;
      text-align: center; }
      @media  (min-width: 48em) {
        .section-collection .field--name-field-coll-specifications > .field__items > .field__item {
          margin: 0 2%;
          width: 20%;
          margin-bottom: 0;
          padding: 0 5px; } }
      .section-collection .field--name-field-coll-specifications > .field__items > .field__item img {
        max-width: 60px; }
      .section-collection .field--name-field-coll-specifications > .field__items > .field__item h2 {
        font-size: 1rem;
        text-align: center;
        text-transform: uppercase;
        font-family: FuturaPT-Bold;
        margin-bottom: 4px; }
        @media  (min-width: 48em) {
          .section-collection .field--name-field-coll-specifications > .field__items > .field__item h2 {
            font-size: 1rem; } }
        @media  (min-width: 48em) {
          .section-collection .field--name-field-coll-specifications > .field__items > .field__item h2 p {
            font-size: 14px; } }
      .section-collection .field--name-field-coll-specifications > .field__items > .field__item .taxonomy-term-description p {
        font-size: 0.8rem;
        text-align: center; }
.section-collection .field--name-field-coll-essentials, .section-collection .field--name-field-coll-features-benefits {
  border-bottom: solid 2px #ebf0f0;
  margin: 30px auto;
  padding: 20px 0 60px 0; }
  .section-collection .field--name-field-coll-essentials h2, .section-collection .field--name-field-coll-features-benefits h2 {
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
    font-family: FuturaPT-Bold;
    margin-bottom: 4px; }
.section-collection .field--name-field-coll-specifications > .field__label, .section-collection .field--name-field-coll-features-benefits > .field__label, .section-collection .field--name-field-coll-essentials > .field__label {
  color: #333;
  font-size: 2em;
  font-family: FuturaPT-Bold;
  text-align: center;
  margin: 1em 0; }
.section-collection .field--name-field-coll-essentials .field__item .field--name-field-coll-ess-image {
  width: 100%; }
  @media  (min-width: 48em) {
    .section-collection .field--name-field-coll-essentials .field__item .field--name-field-coll-ess-image {
      margin: 0 auto; } }
.section-collection .field--name-field-coll-essentials .field__item .field--name-field-coll-essential h2 {
  display: none; }
.section-collection .field--name-field-coll-essentials .field__item .field--name-field-coll-essential > .field__items {
  position: relative;
  right: 0;
  bottom: 0; }
  @media  (min-width: 48em) {
    .section-collection .field--name-field-coll-essentials .field__item .field--name-field-coll-essential > .field__items {
      position: absolute;
      right: 6%;
      top: 18%;
      bottom: initial;
      background-color: rgba(255, 255, 255, 0.93);
      padding: 15px; } }
.section-collection .field--name-field-coll-essentials .field__item .field--name-field-coll-essential .taxonomy-term--essentials {
  text-align: center; }
  @media  (min-width: 48em) {
    .section-collection .field--name-field-coll-essentials .field__item .field--name-field-coll-essential .taxonomy-term--essentials {
      width: 160px; } }
  .section-collection .field--name-field-coll-essentials .field__item .field--name-field-coll-essential .taxonomy-term--essentials .field--name-field-ess-icon img {
    width: 50px; }
  .section-collection .field--name-field-coll-essentials .field__item .field--name-field-coll-essential .taxonomy-term--essentials .field--name-name-field {
    text-transform: uppercase;
    font-family: FuturaPT-Bold; }
.section-collection .field--name-field-coll-features-benefits .field__item {
  width: 25%;
  float: left; }
  .section-collection .field--name-field-coll-features-benefits .field__item .field--name-field-fb-image, .section-collection .field--name-field-coll-features-benefits .field__item .field--name-field-feature-benefit {
    overflow: hidden;
    *zoom: 1; }
  .section-collection .field--name-field-coll-features-benefits .field__item .field--name-field-fb-image img {
    vertical-align: bottom; }
  .section-collection .field--name-field-coll-features-benefits .field__item .field--name-field-benefit-icon .field__item, .section-collection .field--name-field-coll-features-benefits .field__item .field--name-field-feature-benefit .field__item, .section-collection .field--name-field-coll-features-benefits .field__item .field--name-field-fb-image .field__item {
    width: 100%; }
.section-collection .field--name-field-coll-image {
  overflow: hidden; }
  .section-collection .field--name-field-coll-image img {
    vertical-align: bottom;
    min-height: 250px;
    max-width: 1200px;
    max-height: none;
    overflow: hidden;
    height: auto;
    min-width: 100%; }
.section-collection .view-range {
  display: inline-block; }
.section-collection .field--name-field-coll-key-features .field__label, .section-collection .field--name-field-coll-benefits .field__label, .section-collection .field--name-field-properties .field__label {
  margin: 15px 0;
  padding: 15px 0;
  border-top: solid 2px #ebf0f0;
  font-size: 2em;
  color: #333;
  text-align: center;
  font-family: 'FuturaPT-Bold'; }
  @media  (min-width: 48em) {
    .section-collection .field--name-field-coll-key-features .field__label, .section-collection .field--name-field-coll-benefits .field__label, .section-collection .field--name-field-properties .field__label {
      margin: 30px 0;
      padding: 30px 0; } }
.section-collection .field--name-field-coll-key-features .field__label {
  border-top: 0; }
.section-collection .field--name-field-coll-features-benefits .field__items {
  overflow: hidden;
  *zoom: 1;
  text-align: center;
  font-size: 0; }
.section-collection .field--name-field-coll-features-benefits .field__item {
  vertical-align: top;
  width: 100%;
  padding: 0 5px; }
  @media  (min-width: 26em) {
    .section-collection .field--name-field-coll-features-benefits .field__item {
      width: 100%; } }
  @media  (min-width: 48em) {
    .section-collection .field--name-field-coll-features-benefits .field__item {
      width: 50%;
      float: left; } }
  @media  (min-width: 64em) {
    .section-collection .field--name-field-coll-features-benefits .field__item {
      width: 25%;
      float: none;
      display: inline-block; } }
  .section-collection .field--name-field-coll-features-benefits .field__item .view-mode-listitem {
    overflow: hidden;
    *zoom: 1;
    margin-bottom: 15px; }
    @media  (min-width: 26em) {
      .section-collection .field--name-field-coll-features-benefits .field__item .view-mode-listitem {
        margin-bottom: 0; } }
  .section-collection .field--name-field-coll-features-benefits .field__item .group-header .field__item {
    width: 100%;
    float: none;
    padding: 0; }
    .section-collection .field--name-field-coll-features-benefits .field__item .group-header .field__item img {
      width: 100%; }
  .section-collection .field--name-field-coll-features-benefits .field__item .group-left, .section-collection .field--name-field-coll-features-benefits .field__item .group-right {
    float: left;
    padding: 4px 0; }
    .section-collection .field--name-field-coll-features-benefits .field__item .group-left .field__item, .section-collection .field--name-field-coll-features-benefits .field__item .group-right .field__item {
      width: 50%;
      float: left; }
  .section-collection .field--name-field-coll-features-benefits .field__item .group-left {
    width: 100%;
    padding-top: 10px; }
    .section-collection .field--name-field-coll-features-benefits .field__item .group-left .field--name-field-benefit-icon {
      width: 70px;
      height: auto;
      margin: 0 auto; }
      .section-collection .field--name-field-coll-features-benefits .field__item .group-left .field--name-field-benefit-icon .field__item {
        width: 100%;
        margin: 0; }
  .section-collection .field--name-field-coll-features-benefits .field__item .group-right {
    width: 100%;
    text-align: center; }
    .section-collection .field--name-field-coll-features-benefits .field__item .group-right .field--name-description-field .field__item, .section-collection .field--name-field-coll-features-benefits .field__item .group-right .field--name-title .field__item {
      width: 100%; }
    .section-collection .field--name-field-coll-features-benefits .field__item .group-right .field--name-description-field p {
      font-size: 15px; }
    .section-collection .field--name-field-coll-features-benefits .field__item .group-right .field--name-title h2 {
      margin: 0;
      font-size: 1rem;
      font-family: FuturaPT-Bold; }
    .section-collection .field--name-field-coll-features-benefits .field__item .group-right .field--name-title .field--name-description-field {
      font-size: 0.8rem; }
.section-collection .field--name-field-properties {
  clear: both; }
  .section-collection .field--name-field-properties .field__items {
    overflow: hidden;
    *zoom: 1;
    display: block;
    width: 400px;
    margin: 0 auto; }
    .section-collection .field--name-field-properties .field__items .field__item {
      float: left;
      width: 25%; }
  .section-collection .field--name-field-properties .field--name-name-field {
    display: none; }

.view-collections .view-filters {
  margin-bottom: 0.5%;
  margin-top: -10px;
  background-color: #f0f0f0; }

@media (min-width: 1025px) {
  .view-collections-induction .group-left, .view-collections-induction .group-right, .view-collections .group-left, .view-collections .group-right {
    width: 100%; }
  .view-collections-induction .view-filters .views-exposed-widget, .view-collections .view-filters .views-exposed-widget {
    text-align: left;
    width: 33.33%;
    padding: 7.5px;
    vertical-align: top;
    margin: auto;
    display: inline-block; }
  .view-collections .view-filters .views-exposed-widget:first-child {
    padding-left: 15px; }
  .view-collections .view-filters .views-exposed-widget:nth-child(3) {
    padding-right: 15px; } }

@media (max-width: 1024px) {
  .view-collections-induction .group-left, .view-collections-induction .group-right, .view-collections .group-left, .view-collections .group-right {
    width: 100%;
    padding: 15px 0; }
  .view-collections-induction .view-filters .views-exposed-widget, .view-collections .view-filters .views-exposed-widget {
    width: 100%; }
  .view-collections-induction .field--name-title, .view-collections .field--name-title {
    padding: 5px; }
    .view-collections-induction .field--name-title a, .view-collections .field--name-title a {
      font-size: 18px; } }

#views-exposed-form-collections-page a.expand {
  display: block;
  position: absolute;
  cursor: pointer;
  top: 0;
  height: 60px;
  padding: 16px;
  font-size: 0.9rem;
  width: 100%;
  color: #333;
  text-align: center;
  left: 0;
  line-height: 30px; }
  @media  (min-width: 48em) {
    #views-exposed-form-collections-page a.expand {
      font-size: 20px; } }
  #views-exposed-form-collections-page a.expand .triangle {
    width: 0;
    height: 0;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    border-style: solid;
    border-width: 7px 7px 0 7px;
    border-color: #ebebeb transparent transparent transparent;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 5.5px 10px; }

.view-collections-induction, .view-collections {
  position: relative; }
  .view-collections-induction .view-filters, .view-collections .view-filters {
    background-color: #f3f3f3;
    padding-top: 60px; }
    .view-collections-induction .view-filters label, .view-collections .view-filters label {
      font-size: 0.9rem; }
    .view-collections-induction .view-filters .views-exposed-widgets, .view-collections .view-filters .views-exposed-widgets {
      max-height: 900px;
      transition: all 0.5s;
      margin-top: 30px;
      opacity: 1;
      padding-bottom: 30px; }
      .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:not(:checked), .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:checked, .view-collections .view-filters .views-exposed-widgets [type='checkbox']:not(:checked), .view-collections .view-filters .views-exposed-widgets [type='checkbox']:checked {
        position: absolute;
        left: -9999px; }
      .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:not(:checked) + label, .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:checked + label, .view-collections .view-filters .views-exposed-widgets [type='checkbox']:not(:checked) + label, .view-collections .view-filters .views-exposed-widgets [type='checkbox']:checked + label {
        position: relative;
        padding-left: 25px;
        cursor: pointer; }
      .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:not(:checked) + label:before, .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:checked + label:before, .view-collections .view-filters .views-exposed-widgets [type='checkbox']:not(:checked) + label:before, .view-collections .view-filters .views-exposed-widgets [type='checkbox']:checked + label:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 17px;
        height: 17px;
        border: 1px solid #ccc;
        background: #fff; }
      .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:not(:checked) + label:after, .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:checked + label:after, .view-collections .view-filters .views-exposed-widgets [type='checkbox']:not(:checked) + label:after, .view-collections .view-filters .views-exposed-widgets [type='checkbox']:checked + label:after {
        content: '✔';
        position: absolute;
        right: 2px;
        top: 2px;
        font-size: 14px;
        color: #85a312;
        transition: all .2s; }
      .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:not(:checked) + label:after, .view-collections .view-filters .views-exposed-widgets [type='checkbox']:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0); }
      .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:checked + label:after, .view-collections .view-filters .views-exposed-widgets [type='checkbox']:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1); }
      .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:disabled:not(:checked) + label:before, .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:disabled:checked + label:before, .view-collections .view-filters .views-exposed-widgets [type='checkbox']:disabled:not(:checked) + label:before, .view-collections .view-filters .views-exposed-widgets [type='checkbox']:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd; }
      .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:disabled:checked + label:after, .view-collections .view-filters .views-exposed-widgets [type='checkbox']:disabled:checked + label:after {
        color: #999; }
      .view-collections-induction .view-filters .views-exposed-widgets [type='checkbox']:disabled + label, .view-collections .view-filters .views-exposed-widgets [type='checkbox']:disabled + label {
        color: #aaa; }
    .view-collections-induction .view-filters .l-header.shrink .l-header-wrapper, .view-collections .view-filters .l-header.shrink .l-header-wrapper {
      height: 50px;
      overflow: visible; }
    .view-collections-induction .view-filters .views-exposed-widget, .view-collections .view-filters .views-exposed-widget {
      text-align: left;
      padding: 5px;
      vertical-align: top;
      margin: auto;
      display: inline-block; }
      .view-collections-induction .view-filters .views-exposed-widget label:first-child, .view-collections .view-filters .views-exposed-widget label:first-child {
        font-family: 'FuturaPT-Book';
        padding-left: 22px;
        font-size: 18px;
        margin-bottom: 15px;
        display: block; }
      .view-collections-induction .view-filters .views-exposed-widget label, .view-collections .view-filters .views-exposed-widget label {
        text-transform: uppercase; }
      .view-collections-induction .view-filters .views-exposed-widget .form-type-bef-checkbox, .view-collections .view-filters .views-exposed-widget .form-type-bef-checkbox {
        background-color: #ebebeb;
        border: thin solid #fff;
        padding: 12px 24px; }
        .view-collections-induction .view-filters .views-exposed-widget .form-type-bef-checkbox input, .view-collections .view-filters .views-exposed-widget .form-type-bef-checkbox input {
          float: right;
          position: absolute;
          z-index: -1000;
          left: -1000px;
          overflow: hidden;
          clip: rect(0 0 0 0);
          height: 1px;
          width: 1px;
          margin: -1px;
          padding: 0;
          border: 0; }
        .view-collections-induction .view-filters .views-exposed-widget .form-type-bef-checkbox input[type=checkbox] + label, .view-collections .view-filters .views-exposed-widget .form-type-bef-checkbox input[type=checkbox] + label {
          padding-left: 0;
          padding-right: 30px;
          height: 15px;
          width: 100%;
          display: inline-block;
          line-height: 15px;
          background-repeat: no-repeat;
          font-size: 15px;
          vertical-align: middle;
          cursor: pointer; }
        .view-collections-induction .view-filters .views-exposed-widget .form-type-bef-checkbox input[type=checkbox]:checked + label, .view-collections .view-filters .views-exposed-widget .form-type-bef-checkbox input[type=checkbox]:checked + label {
          background-position: 100% -15px; }
        .view-collections-induction .view-filters .views-exposed-widget .form-type-bef-checkbox label, .view-collections .view-filters .views-exposed-widget .form-type-bef-checkbox label {
          background-image: url('/sites/all/themes/custom/greenpan15/img/csscheckbox.png');
          background-position: 100% 0;
          background-size: 15px; }
  .view-collections-induction .group-header, .view-collections .group-header {
    position: relative; }
    .view-collections-induction .group-header img, .view-collections .group-header img {
      width: 100%; }
  .view-collections-induction .field--name-field-coll-title-overview-page, .view-collections .field--name-field-coll-title-overview-page {
    position: absolute;
    top: 8%;
    left: 0;
    background: rgba(51, 51, 51, 0.82);
    padding: 15px; }
    @media  (min-width: 64em) {
      .view-collections-induction .field--name-field-coll-title-overview-page, .view-collections .field--name-field-coll-title-overview-page {
        padding: 30px 40px; } }
    .view-collections-induction .field--name-field-coll-title-overview-page .field__item, .view-collections .field--name-field-coll-title-overview-page .field__item {
      margin: 0; }
      .view-collections-induction .field--name-field-coll-title-overview-page .field__item p, .view-collections .field--name-field-coll-title-overview-page .field__item p {
        font-size: 0.9rem;
        color: #fff; }
        @media  (min-width: 48em) {
          .view-collections-induction .field--name-field-coll-title-overview-page .field__item p, .view-collections .field--name-field-coll-title-overview-page .field__item p {
            font-size: 1.5rem; } }

/* Language*/
.greenpan-popup-wrapper {
  color: #333;
  font-size: 1.3rem;
  margin: 20px 0;
  text-align: center;
  padding: 40px; }
  .greenpan-popup-wrapper a {
    color: #85a312; }

.greenpan-popup-wrapper p {
  font-family: 'FuturaPT-Light'; }

.greenpan-popup-wrapper h2 {
  margin: 5px 0 0 0;
  font-family: 'FuturaPT-Bold'; }
  @media  (min-width: 48em) {
    .greenpan-popup-wrapper h2 {
      margin: 0.83em 0 0 0; } }

.greenpan-overlay {
  opacity: 0;
  width: 100%;
  height: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; }
  .greenpan-overlay .greenpan-popup-wrapper {
    opacity: 0; }
  .greenpan-overlay .switcher {
    height: 0;
    width: 0;
    padding: 0; }

.greenpan-overlay.active {
  opacity: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: auto; }
  .greenpan-overlay.active .greenpan-popup-wrapper {
    opacity: 1; }
  .greenpan-overlay.active .switcher {
    padding: 20px;
    height: auto;
    width: 240px; }
    @media  (min-width: 26em) {
      .greenpan-overlay.active .switcher {
        width: 290px; } }
    @media  (min-width: 48em) {
      .greenpan-overlay.active .switcher {
        width: 450px; } }

.block--language-region-language-region-lang-select-ajax {
  display: block; }
  @media  (min-width: 48em) {
    .block--language-region-language-region-lang-select-ajax {
      display: inline-block; } }

.greenpan-popup-wrapper a.button, .greenpan-popup-wrapper input.button {
  text-transform: uppercase;
  border-radius: 0;
  color: #FFF;
  background-color: #333;
  padding: 10px;
  min-width: 130px;
  font-size: 1.2rem;
  text-align: center;
  display: block;
  margin: 40px auto 10px auto;
  border-radius: 3px; }
  .greenpan-popup-wrapper a.button:hover, .greenpan-popup-wrapper input.button:hover {
    background-color: #85a312; }

.greenpan-popup-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: fixed;
  width: 80%;
  background-color: #ebebeb;
  padding: 5px; }
  @media  (min-width: 26em) {
    .greenpan-popup-wrapper {
      width: 290px;
      padding: 20px; } }
  @media  (min-width: 48em) {
    .greenpan-popup-wrapper {
      width: 450px; } }
  .greenpan-popup-wrapper .close-cookie {
    cursor: pointer;
    height: 40px;
    width: 40px;
    position: absolute;
    top: -45px;
    left: 0; }
    @media  (min-width: 48em) {
      .greenpan-popup-wrapper .close-cookie {
        right: -40px;
        top: -40px;
        left: initial; } }
    .greenpan-popup-wrapper .close-cookie:after {
      content: '';
      background-image: url('/sites/all/themes/custom/greenpan15/svg/cross.svg');
      background-repeat: no-repeat;
      background-size: contain;
      height: 40px;
      width: 40px;
      color: #333;
      display: block;
      background-color: #ebebeb;
      border: 10px solid #ebebeb; }

.language-region-load-active-by-continent-select-ajax {
  text-align: center; }
  @media  (min-width: 48em) {
    .language-region-load-active-by-continent-select-ajax {
      text-align: left; } }
  .language-region-load-active-by-continent-select-ajax > div {
    position: relative; }
  .language-region-load-active-by-continent-select-ajax .lg-switch {
    color: #fff; }
    @media  (min-width: 26em) {
      .language-region-load-active-by-continent-select-ajax .lg-switch:after {
        content: '|';
        padding-left: 12px; } }
  .language-region-load-active-by-continent-select-ajax .greenpan-popup-wrapper .green-lang-logo {
    display: block;
    width: 170px;
    margin: 0 auto; }
  .language-region-load-active-by-continent-select-ajax .greenpan-popup-wrapper label {
    font-family: 'FuturaPT-Bold';
    color: #333;
    font-size: 1.3rem;
    margin: 20px 0; }
  .language-region-load-active-by-continent-select-ajax .greenpan-popup-wrapper select {
    cursor: pointer;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    outline: none;
    border-radius: 0;
    border: 1px solid #333;
    font-size: 1.1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: white url('/sites/all/themes/custom/greenpan15/img/arrow-down.jpg') no-repeat scroll 96% center/18px auto;
    outline: none; }

/*General Inquiry*/
.section-general-inquiry .node--webform .webform-component-select, .section-product-question .node--webform .webform-component-select {
  width: 100%; }

.section-general-inquiry .node--webform > header > .node__title, .section-product-question .node--webform > header > .node__title, .section-pr-or-business-related-inquiry .node--webform > header > .node__title {
  display: none; }

/*  Form landing */
.section-contact-us .group-header p {
  margin: 1em 0; }
.section-contact-us .group-header h1 {
  font-family: 'FuturaPT-Bold';
  color: #333; }
.section-contact-us .group-header .field--name-body {
  width: 100%;
  margin-bottom: 40px; }
  @media  (min-width: 26em) {
    .section-contact-us .group-header .field--name-body {
      width: 85%;
      margin: 0 auto; } }
  @media  (min-width: 64em) {
    .section-contact-us .group-header .field--name-body {
      width: 55%; } }
.section-contact-us .group-footer {
  padding: 0; }
  .section-contact-us .group-footer .field--name-field-page-footer-text {
    width: 100%;
    margin-bottom: 40px;
    text-align: left; }
    @media  (min-width: 26em) {
      .section-contact-us .group-footer .field--name-field-page-footer-text {
        width: 85%;
        margin: 0 auto; } }
    @media  (min-width: 64em) {
      .section-contact-us .group-footer .field--name-field-page-footer-text {
        width: 55%; } }
    .section-contact-us .group-footer .field--name-field-page-footer-text p {
      font-size: 1rem;
      font-weight: normal;
      font-style: normal;
      color: #333; }

.contact-quiclinks {
  overflow: hidden;
  *zoom: 1; }
  .contact-quiclinks li {
    width: 50%;
    float: left;
    background-color: #333; }
    @media  (min-width: 48em) {
      .contact-quiclinks li {
        margin: 4px;
        width: 48%; } }
    .contact-quiclinks li:hover {
      background-color: #85a312; }
    .contact-quiclinks li a {
      padding: 15px;
      min-height: 150px;
      display: block;
      transition: all 0.3s ease-in-out;
      box-shadow: 0 0 0 1px #e6e6e6;
      text-align: center; }
      @media  (min-width: 26em) {
        .contact-quiclinks li a {
          padding: 20px 5px;
          border-radius: 3px;
          min-height: 0; } }
      .contact-quiclinks li a:hover {
        box-shadow: 0 0 0 1px #b0b0b0; }
      .contact-quiclinks li a h2 {
        margin: 0;
        font-size: 1.2rem; }
        @media  (min-width: 48em) {
          .contact-quiclinks li a h2 {
            font-size: 1.5rem; } }
      .contact-quiclinks li a h2, .contact-quiclinks li a p {
        color: #fff;
        margin: 0; }

/* End form landing */
/* Form width */
.node-type-webform {
  background-color: #ebebeb; }
  .node-type-webform .l-main {
    width: 95%;
    padding: 0; }
    @media  (min-width: 48em) {
      .node-type-webform .l-main {
        width: 65%; } }
    @media  (min-width: 75em) {
      .node-type-webform .l-main {
        width: 50%; } }
    @media  (min-width: 85em) {
      .node-type-webform .l-main {
        width: 40%; } }

/* End form width */
/* Confirmation */
.page-node-done .l-main {
  margin-top: 300px; }
.page-node-done .webform-confirmation {
  width: 95%;
  margin: 0 auto;
  text-align: center; }
  @media  (min-width: 48em) {
    .page-node-done .webform-confirmation {
      width: 30%; } }
.page-node-done .links {
  text-align: center;
  margin-top: 54px; }
  .page-node-done .links a {
    background-color: #333;
    color: #fff;
    text-transform: uppercase;
    padding: 10px;
    display: inline-block; }
    .page-node-done .links a:hover {
      background-color: #85a312; }

/* End confirmation */
label {
  margin-bottom: 9px;
  display: inline-block; }

.node--webform {
  /* Checkbox*/
  /* End checkbox*/ }
  .node--webform input[type='radio']:not(:checked) + label:before, .node--webform input[type='checkbox']:not(:checked) + label:before, .node--webform input[type='radio']:checked + label:before, .node--webform input[type='checkbox']:checked + label:before {
    content: '';
    position: absolute;
    top: 0;
    width: 17px;
    left: 0;
    height: 17px;
    border: 1px solid #ccc;
    background: #FFF; }
  .node--webform input[type='radio']:not(:checked) + label:after, .node--webform input[type='checkbox']:not(:checked) + label:after, .node--webform input[type='radio']:checked + label:after, .node--webform input[type='checkbox']:checked + label:after {
    content: '✔';
    position: absolute;
    left: 4px;
    top: 0;
    font-size: 14px;
    color: #85a312;
    transition: all .2s; }
  .node--webform input[type='checkbox']:not(:checked), .node--webform input[type='checkbox']:checked, .node--webform input[type='radio']:not(:checked), .node--webform input[type='radio']:checked {
    position: absolute;
    left: -9999px; }
  .node--webform label.option {
    margin-bottom: 7px;
    padding-left: 35px; }
  .node--webform input[type='radio']:not(:checked) + label:after, .node--webform input[type='checkbox']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0); }
  .node--webform input[type='radio']:checked + label:after, .node--webform input[type='checkbox']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  .node--webform input[type='radio']:disabled:not(:checked) + label:before, .node--webform input[type='radio']:disabled:checked + label:before, .node--webform input[type='checkbox']:disabled:not(:checked) + label:before, .node--webform input[type='checkbox']:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd; }
  .node--webform input[type='radio']:disabled:checked + label:after input[type='checkbox']:disabled:checked + label:after {
    color: #999; }
  .node--webform input[type='radio']:disabled + label, .node--webform input[type='checkbox']:disabled + label {
    color: #aaa; }
  .node--webform .field--name-field-page-intro {
    background-color: #ebebeb;
    padding: 10px;
    margin-bottom: 0; }
    .node--webform .field--name-field-page-intro p {
      font-size: 15px;
      line-height: 20px;
      margin: 0;
      padding: 0px; }
      @media  (min-width: 48em) {
        .node--webform .field--name-field-page-intro p {
          padding: 15px; } }
  .node--webform .webform-component-markup {
    margin: 30px 0; }
  .node--webform .webform-component-select, .node--webform .webform-component-date {
    width: 100%; }
    @media  (min-width: 26em) {
      .node--webform .webform-component-select, .node--webform .webform-component-date {
        width: 50% !important;
        float: left; } }
    .node--webform .webform-component-select .webform-container-inline, .node--webform .webform-component-date .webform-container-inline {
      width: 100%;
      background-color: #fff; }
      .node--webform .webform-component-select .webform-container-inline label, .node--webform .webform-component-date .webform-container-inline label {
        display: none; }
      .node--webform .webform-component-select .webform-container-inline .form-item, .node--webform .webform-component-date .webform-container-inline .form-item {
        float: left;
        width: 33.32%; }
        .node--webform .webform-component-select .webform-container-inline .form-item select, .node--webform .webform-component-date .webform-container-inline .form-item select {
          cursor: pointer;
          color: #7a7a7a;
          float: left;
          width: 100%;
          background-color: #fff;
          padding: 10px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border-radius: 0;
          background: white url('/sites/all/themes/custom/greenpan15/img/arrow-down.jpg') no-repeat scroll 96% center/18px auto;
          outline: none; }
  .node--webform .webform-component--do-you-use-metal-ustensils-in-the-cookware-forks-included- .form-item {
    width: 50%;
    float: left; }
    @media  (min-width: 26em) {
      .node--webform .webform-component--do-you-use-metal-ustensils-in-the-cookware-forks-included- .form-item {
        width: 100%;
        float: none; } }
  .node--webform .webform-component--newsletter {
    margin-top: 15px; }
  .node--webform .webform-component--newsletter .form-checkboxes label {
    text-transform: initial; }
  .node--webform .webform-component-select select {
    background: white url('/sites/all/themes/custom/greenpan15/img/arrow-down.jpg') no-repeat scroll 96% center/18px auto;
    background-color: #fff;
    padding: 10px;
    border-radius: 0;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    font-size: 0.85rem;
    font-family: Arial;
    color: #a5a5a5;
    outline: none; }
  .node--webform .webform-component-textarea {
    display: inline-block; }
  .node--webform .webform-component-file > label {
    margin-top: 20px;
    display: inline-block; }
  .node--webform .webform-component-file .form-managed-file {
    display: inline-block;
    width: 100%; }
    .node--webform .webform-component-file .form-managed-file .greenpan-upload-button {
      text-transform: uppercase;
      padding: 10px 50px;
      margin-bottom: 20px;
      background-color: #333;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      border-radius: 2px; }
      .node--webform .webform-component-file .form-managed-file .greenpan-upload-button:hover {
        background-color: #85a312; }
  .node--webform .webform-component-file input[type='file'], .node--webform .webform-component-file input[type='submit'] {
    background-color: #333;
    color: #fff;
    border-radius: 2px;
    font-size: 0.85rem;
    width: 100%; }
    @media  (min-width: 26em) {
      .node--webform .webform-component-file input[type='file'], .node--webform .webform-component-file input[type='submit'] {
        width: 40%; } }
  .node--webform .webform-component-file input[type='file'] {
    margin-bottom: 20px;
    padding: 5px; }
    @media  (min-width: 26em) {
      .node--webform .webform-component-file input[type='file'] {
        float: left;
        margin-bottom: 0;
        padding: 0; } }
    .node--webform .webform-component-file input[type='file']::-webkit-file-upload-button {
      background: transparent;
      color: #fff;
      border: 1px solid #474747;
      border-radius: 2px;
      cursor: pointer;
      font-size: 0.8rem;
      padding: 5px; }
      .node--webform .webform-component-file input[type='file']::-webkit-file-upload-button:hover {
        background-color: #85a312; }
  .node--webform .webform-component-file input[type='submit'] {
    padding: 10px;
    margin-bottom: 3px; }
    @media  (min-width: 26em) {
      .node--webform .webform-component-file input[type='submit'] {
        float: right;
        cursor: pointer;
        padding: 5px;
        margin-bottom: 0; } }
    .node--webform .webform-component-file input[type='submit']:hover {
      background-color: #85a312; }
  .node--webform .webform-component-file .description {
    display: inline-block;
    text-align: left;
    width: 100%;
    font-size: 0.75rem;
    color: #9e9e9e; }
  .node--webform .webform-component-textarea input[type='text'], .node--webform .webform-component-textarea input[type='email'], .node--webform .webform-component-textarea textarea, .node--webform .form-item input[type='text'], .node--webform .form-item input[type='email'], .node--webform .form-item textarea {
    padding: 10px;
    width: 100%;
    font-size: 0.85rem;
    font-family: Arial;
    color: #333;
    outline: none; }
  .node--webform .form-item {
    padding: 10px;
    width: 100%;
    margin-bottom: 0; }
    .node--webform .form-item label {
      text-transform: uppercase;
      font-size: 0.83rem; }
    .node--webform .form-item .form-item {
      padding: 0;
      position: relative;
      margin-bottom: 10px; }
      @media  (min-width: 26em) {
        .node--webform .form-item .form-item {
          margin-bottom: 0; } }
  .node--webform .webform-component--question, .node--webform .webform-component--question input {
    width: 100% !important; }
  .node--webform .webform-component-textfield, .node--webform .webform-component-email {
    width: 100%; }
    @media  (min-width: 26em) {
      .node--webform .webform-component-textfield, .node--webform .webform-component-email {
        width: 50%;
        float: left; } }
  .node--webform .form-actions {
    width: 100%;
    display: inline-block; }
    .node--webform .form-actions input {
      text-transform: uppercase;
      padding: 10px 40px;
      font-size: 20px;
      background-color: #333;
      color: #fff;
      font-weight: bold;
      display: block;
      margin: 0 auto;
      cursor: pointer;
      border-radius: 2px;
      margin-top: 15px; }
      .node--webform .form-actions input:hover {
        background-color: #85a312; }

@media (max-width: 1024px) {
  .node-type-webform .l-main {
    width: 70%; } }

@media (max-width: 35em) {
  .node-type-webform .l-main {
    width: 100%; } }

.messages--error {
  padding: 10px;
  background-image: none;
  background-color: red;
  margin: 10px;
  color: #fff;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto; }

.leaflet-top {
  z-index: 9; }

.leaflet-popup .node__title a {
  color: #333; }

.leaflet-popup-content a {
  color: #85a312; }

.page-store-locator .l-content {
  margin-top: 80px; }

.view-store-locator.view-display-id-page {
  position: relative; }
  .view-store-locator.view-display-id-page .view-filters-attachment {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    width: 100%; }
    @media  (min-width: 48em) {
      .view-store-locator.view-display-id-page .view-filters-attachment {
        width: 25%;
        position: absolute;
        bottom: 10px;
        right: 10px;
        height: 90%; } }
    .view-store-locator.view-display-id-page .view-filters-attachment .view-filters .views-submit-button {
      float: none;
      display: inline-block; }
      .view-store-locator.view-display-id-page .view-filters-attachment .view-filters .views-submit-button .form-submit {
        padding: 10px 15px; }
    .view-store-locator.view-display-id-page .view-filters-attachment .view-filters .views-exposed-widget {
      width: 100%;
      padding: 0; }
      .view-store-locator.view-display-id-page .view-filters-attachment .view-filters .views-exposed-widget .form-item-field-geofield-distance-distance, .view-store-locator.view-display-id-page .view-filters-attachment .view-filters .views-exposed-widget .form-type-select, .view-store-locator.view-display-id-page .view-filters-attachment .view-filters .views-exposed-widget .geofield-proximity-origin-from {
        display: none; }
    .view-store-locator.view-display-id-page .view-filters-attachment .view-filters .form-type-geofield-proximity .form-item {
      margin-bottom: 10px; }
      .view-store-locator.view-display-id-page .view-filters-attachment .view-filters .form-type-geofield-proximity .form-item .form-select {
        padding: 5px; }
    .view-store-locator.view-display-id-page .view-filters-attachment .view-filters .form-type-geofield-proximity .geofield-proximity-origin-from {
      padding-top: 10px; }
    .view-store-locator.view-display-id-page .view-filters-attachment .views-row {
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 25px;
      margin-bottom: 25px; }
      .view-store-locator.view-display-id-page .view-filters-attachment .views-row .field--name-field-store-phone a, .view-store-locator.view-display-id-page .view-filters-attachment .views-row .field--name-field-store-url a {
        color: #85a312; }
      .view-store-locator.view-display-id-page .view-filters-attachment .views-row div {
        margin-bottom: 3px; }
    .view-store-locator.view-display-id-page .view-filters-attachment .views-row-last {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0; }
    .view-store-locator.view-display-id-page .view-filters-attachment .attachment .field--name-title h2 {
      margin-top: 0; }
    .view-store-locator.view-display-id-page .view-filters-attachment .attachment .field--name-field-store-url a {
      color: #85a312; }
  .view-store-locator.view-display-id-page .views-widget-filter-field_geofield_distance input {
    box-shadow: inset 0 0 0 1px #e6e6e6;
    padding: 5px;
    border-radius: 2px;
    width: 100%; }

@media  (min-width: 48em) {
  .view-where-to-buy {
    margin-top: 80px; } }
.view-where-to-buy .view-content {
  width: 100%; }
  @media  (min-width: 48em) {
    .view-where-to-buy .view-content {
      width: 500px;
      margin: 0 auto; } }
.view-where-to-buy .views-row {
  margin-bottom: 55px; }
.view-where-to-buy .field--name-field-store-url {
  width: 50%;
  float: left;
  text-align: right;
  padding-right: 30px; }
  .view-where-to-buy .field--name-field-store-url a {
    color: #333;
    font-size: 1.3rem; }
.view-where-to-buy .field--name-field-store-logo {
  width: 50%;
  float: left;
  padding-left: 30px; }

.block--menu-block-2 {
  background-color: #f5f5f5; }
  .block--menu-block-2 .menu {
    overflow: hidden;
    *zoom: 1;
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 50px 0;
    text-align: center; }
    .block--menu-block-2 .menu .leaf {
      list-style-image: none;
      list-style-type: none;
      display: inline-block;
      min-width: 150px;
      margin-bottom: 5px; }
      @media  (min-width: 48em) {
        .block--menu-block-2 .menu .leaf {
          min-width: 250px; } }
      .block--menu-block-2 .menu .leaf a {
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        background-color: #828282;
        border-radius: 2px;
        margin: 0 10px;
        font-size: 1rem;
        padding: 15px 30px;
        display: inline-block; }
        @media  (min-width: 48em) {
          .block--menu-block-2 .menu .leaf a {
            font-size: 1.2rem; } }
        .block--menu-block-2 .menu .leaf a:hover {
          background-color: #85a312; }
    .block--menu-block-2 .menu .active a {
      background-color: #333;
      padding: 15px 30px;
      color: #fff;
      text-transform: uppercase;
      display: inline-block;
      border-radius: 2px; }
    .block--menu-block-2 .menu .active a:hover {
      background-color: #474747; }

.block--greenpan-sitewide-greenpan-where-to-by-banner .block__title {
  display: none; }
.block--greenpan-sitewide-greenpan-where-to-by-banner .block__content {
  text-align: center;
  padding: 50px 15px;
  font-size: 18px;
  line-height: 30px;
  display: block;
  position: relative;
  background-color: #f0f0f0; }
  @media  (min-width: 48em) {
    .block--greenpan-sitewide-greenpan-where-to-by-banner .block__content {
      padding: 40px 20%;
      margin-top: 80px; } }
  .block--greenpan-sitewide-greenpan-where-to-by-banner .block__content .contactus {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1em;
    padding-right: 1em;
    max-width: 768px; }
    .block--greenpan-sitewide-greenpan-where-to-by-banner .block__content .contactus p {
      margin-bottom: 20px;
      line-height: 32px; }
      @media  (min-width: 48em) {
        .block--greenpan-sitewide-greenpan-where-to-by-banner .block__content .contactus p {
          font-size: 1.3rem;
          margin-bottom: 40px; } }
    .block--greenpan-sitewide-greenpan-where-to-by-banner .block__content .contactus a {
      text-transform: uppercase;
      color: #fff;
      background-color: #333;
      font-size: 100%;
      padding: 5px;
      display: inline-block;
      border-radius: 3px; }
      @media  (min-width: 26em) {
        .block--greenpan-sitewide-greenpan-where-to-by-banner .block__content .contactus a {
          padding: 10px; } }
      @media  (min-width: 48em) {
        .block--greenpan-sitewide-greenpan-where-to-by-banner .block__content .contactus a {
          padding: 15px 30px; } }
      .block--greenpan-sitewide-greenpan-where-to-by-banner .block__content .contactus a:hover {
        background-color: #85a312; }

.section-user .tabs--primary {
  display: none; }
.section-user .form-item {
  margin-bottom: 2em; }
.section-user .form-submit {
  text-transform: uppercase;
  padding: 15px 30px;
  background-color: #333;
  color: #fff;
  font-family: 'FuturaPT-Bold';
  border-radius: 2px; }
  .section-user .form-submit:hover {
    background-color: #85a312; }
.section-user .user-login .description {
  color: #85a312;
  margin-top: 5px;
  font-size: 0.8rem; }
.section-user .user-login input {
  box-shadow: 0 0 0 1px #e0e0e0; }
.section-user .user-login label {
  font-weight: normal;
  font-size: 1rem;
  padding-bottom: 6px; }

.block--greenpan-sitewide-greenpan-cookie-policy .block__title {
  display: none; }
.block--greenpan-sitewide-greenpan-cookie-policy .cookie-policy {
  background-color: rgba(32, 32, 32, 0.8);
  padding: 15px 5px;
  z-index: 999;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.4s ease-in-out; }
  .block--greenpan-sitewide-greenpan-cookie-policy .cookie-policy p {
    color: #fff;
    font-size: 1rem;
    float: left;
    line-height: 34px; }
  .block--greenpan-sitewide-greenpan-cookie-policy .cookie-policy button {
    float: right;
    width: 33%;
    max-width: 120px;
    padding: 8px 10px;
    display: block; }
.block--greenpan-sitewide-greenpan-cookie-policy .hidden {
  bottom: -65px; }

.green-lang-logo {
  max-width: 200px !important;
  display: block;
  margin: 15px auto; }

.paragraphs-item-text-with-image {
  overflow: hidden;
  *zoom: 1;
  overflow: visible;
  margin-top: 30px;
  padding-right: 15px;
  padding-left: 15px; }
  @media  (min-width: 48em) {
    .paragraphs-item-text-with-image {
      margin-top: 65px; } }
  .paragraphs-item-text-with-image[data-image-align='left'] .group-left, .paragraphs-item-text-with-image[data-image-align='left'] .group-right {
    width: 100%;
    display: block; }
    @media  (min-width: 48em) {
      .paragraphs-item-text-with-image[data-image-align='left'] .group-left, .paragraphs-item-text-with-image[data-image-align='left'] .group-right {
        width: 50% !important; } }
  .paragraphs-item-text-with-image[data-image-align='left'] .group-left {
    float: left; }
  .paragraphs-item-text-with-image[data-image-align='left'] .group-right {
    float: right; }
    @media  (min-width: 64em) {
      .paragraphs-item-text-with-image[data-image-align='left'] .group-right {
        padding-left: 75px; } }
  .paragraphs-item-text-with-image[data-image-align='right'] .group-left, .paragraphs-item-text-with-image[data-image-align='right'] .group-right {
    width: 100%;
    display: block; }
    @media  (min-width: 48em) {
      .paragraphs-item-text-with-image[data-image-align='right'] .group-left, .paragraphs-item-text-with-image[data-image-align='right'] .group-right {
        width: 50% !important; } }
  .paragraphs-item-text-with-image[data-image-align='right'] .group-left {
    float: right; }
  .paragraphs-item-text-with-image[data-image-align='right'] .group-right {
    float: left; }
    @media  (min-width: 64em) {
      .paragraphs-item-text-with-image[data-image-align='right'] .group-right {
        padding-right: 75px; } }
  .paragraphs-item-text-with-image[data-image-align='right'] .group-left {
    margin-top: -5%;
    margin-right: -5%; }
  .paragraphs-item-text-with-image[data-image-align='left'] .group-left {
    margin-top: -5%;
    margin-left: -5%; }
  @media  (min-width: 64em) {
    .paragraphs-item-text-with-image {
      margin-top: 70px; } }
  .paragraphs-item-text-with-image img {
    width: 100%; }
  .paragraphs-item-text-with-image .field--name-field-paragraph-title {
    font-family: 'FuturaPT-Bold';
    font-size: 1.2rem;
    padding-bottom: 13px; }
    @media  (min-width: 48em) {
      .paragraphs-item-text-with-image .field--name-field-paragraph-title {
        font-size: 1.9rem; } }
  .paragraphs-item-text-with-image .field--name-field-paragraph-text p {
    font-size: 1rem;
    margin-bottom: 18px; }
    @media  (min-width: 48em) {
      .paragraphs-item-text-with-image .field--name-field-paragraph-text p {
        font-size: 1.1rem; } }
    .paragraphs-item-text-with-image .field--name-field-paragraph-text p:last-child {
      margin-bottom: 0; }

.field--name-field-paragraph-link a {
  font-family: 'FuturaPT-Bold';
  display: inline-block;
  margin: 20px 0;
  font-size: 0.9rem;
  color: #333; }
  @media  (min-width: 48em) {
    .field--name-field-paragraph-link a {
      font-size: 1rem; } }
  .field--name-field-paragraph-link a:before {
    content: '>>';
    margin-right: 5px; }

.paragraphs-item-text-with-image[data-image-slidein='yes'][data-image-align='right'] .field--name-field-paragraph-image {
  margin-right: -40%;
  opacity: 0; }
.paragraphs-item-text-with-image[data-image-slidein='yes'][data-image-align='right'].slide .field--name-field-paragraph-image {
  margin-right: -10%;
  opacity: 1;
  transition: all 0.4s ease-in; }
.paragraphs-item-text-with-image[data-image-slidein='yes'][data-image-align='left'] .field--name-field-paragraph-image {
  margin-left: -40%;
  opacity: 0; }
.paragraphs-item-text-with-image[data-image-slidein='yes'][data-image-align='left'].slide .field--name-field-paragraph-image {
  margin-left: -10%;
  opacity: 1;
  transition: all 0.4s ease-in; }

.paragraphs-item-banner {
  text-align: center;
  position: relative;
  padding: 40px 20px;
  margin: 40px auto; }
  @media  (min-width: 64em) {
    .paragraphs-item-banner {
      padding: 40px 20%;
      margin: 0; } }
  .paragraphs-item-banner .field--name-field-file-image-title-text {
    display: block;
    font-size: 2rem;
    margin-bottom: 25px;
    font-family: 'FuturaPT-Bold';
    color: #fff; }
    .paragraphs-item-banner .field--name-field-file-image-title-text p {
      font-size: 1.2rem;
      line-height: 30px; }
  .paragraphs-item-banner .field--name-field-banner-button a {
    background-color: #333;
    padding: 15px 30px;
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 30px;
    border-radius: 2px; }

/*Environment*/
[data-color='green_env'] {
  background-image: url('../img/green-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover; }
  [data-color='green_env'] .field--name-field-banner-text p {
    color: #fff; }
  [data-color='green_env'] .field--name-field-banner-button a:hover {
    background-color: #879a20;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.04706) 40%, rgba(0, 0, 0, 0.09804)); }

/*Grey*/
[data-color='grey'] {
  background-color: #f0f0f0; }
  [data-color='grey'] .field--name-field-banner-text p {
    color: #333; }
  [data-color='grey'] .field--name-field-banner-button a:hover {
    background-color: #85a312; }

/*Green*/
[data-color='green'] {
  background-color: #85a312; }
  [data-color='green'] .field--name-field-banner-text p {
    color: #fff; }

.paragraphs-item-video {
  margin: 40px auto; }
  @media  (min-width: 64em) {
    .paragraphs-item-video {
      margin: 0; } }
  .paragraphs-item-video .field--name-field-video-video {
    padding: 40px 0; }
  .paragraphs-item-video .field--name-field-video-title {
    display: block;
    font-size: 2rem;
    margin-top: 25px;
    font-family: 'FuturaPT-Bold';
    text-align: center; }
  .paragraphs-item-video iframe {
    max-width: 100%;
    display: block;
    margin: 0 auto; }

.field--name-field-buttonbar-buttons > .field__items {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .field--name-field-buttonbar-buttons > .field__items > .field__item {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    @-moz-document url-prefix() {
      img {
        margin: 0 0 -6px 0; } }
 }
    @media  (min-width: 48em) {
      .field--name-field-buttonbar-buttons > .field__items > .field__item {
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%; } }
    @media  (min-width: 64em) {
      .field--name-field-buttonbar-buttons > .field__items > .field__item {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1; } }
    .field--name-field-buttonbar-buttons > .field__items > .field__item img {
      width: 100%; }
    .field--name-field-buttonbar-buttons > .field__items > .field__item .content {
      position: relative; }
    .field--name-field-buttonbar-buttons > .field__items > .field__item .field--name-field-button-title, .field--name-field-buttonbar-buttons > .field__items > .field__item .field--name-field-button-subtitle {
      text-align: center;
      color: #fff;
      width: 100%;
      height: 100%; }
    .field--name-field-buttonbar-buttons > .field__items > .field__item .field--name-field-button-subtitle {
      padding: 0 5px;
      position: absolute;
      opacity: 0;
      top: 10px;
      transition: all 0.4s ease-in; }
      @media  (min-width: 48em) {
        .field--name-field-buttonbar-buttons > .field__items > .field__item .field--name-field-button-subtitle {
          padding: 0 15px;
          top: 20px; } }
      .field--name-field-buttonbar-buttons > .field__items > .field__item .field--name-field-button-subtitle p {
        text-align: center;
        color: #fff;
        font-size: 0.9rem; }
        @media  (min-width: 26em) {
          .field--name-field-buttonbar-buttons > .field__items > .field__item .field--name-field-button-subtitle p {
            font-size: 1rem; } }
      .field--name-field-buttonbar-buttons > .field__items > .field__item .field--name-field-button-subtitle .field__items {
        display: table;
        width: 100%; }
        .field--name-field-buttonbar-buttons > .field__items > .field__item .field--name-field-button-subtitle .field__items .field__item {
          display: table-cell;
          vertical-align: middle; }
    .field--name-field-buttonbar-buttons > .field__items > .field__item .field--name-field-button-title {
      z-index: 80;
      padding: 0 5px;
      position: absolute;
      text-transform: uppercase;
      display: block;
      transition: all 0.4s ease-in;
      top: 20%; }
      @media  (min-width: 26em) {
        .field--name-field-buttonbar-buttons > .field__items > .field__item .field--name-field-button-title {
          top: 20%; } }
      @media  (min-width: 64em) {
        .field--name-field-buttonbar-buttons > .field__items > .field__item .field--name-field-button-title {
          top: 45%; } }

.paragraphs-item-button {
  position: relative;
  z-index: 100; }
  .paragraphs-item-button .group-back {
    font-size: 0; }
  .paragraphs-item-button .group-front {
    position: absolute;
    height: 30%;
    display: block;
    width: 100%;
    top: 70%;
    transition: all 0.2s ease-in;
    background: rgba(133, 163, 18, 0.8); }
    @media  (min-width: 26em) {
      .paragraphs-item-button .group-front {
        height: 35%;
        top: 65%; } }
    @media  (min-width: 64em) {
      .paragraphs-item-button .group-front {
        background: none;
        height: 100%;
        top: 0; } }
  .paragraphs-item-button .field__items, .paragraphs-item-button .field__item, .paragraphs-item-button h2, .paragraphs-item-button a {
    height: 100%; }
  .paragraphs-item-button .field--name-field-button-title .field__item {
    font-size: 1rem;
    z-index: 10;
    background: none;
    width: 100%;
    transition: all 0.2s ease-in;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-family: 'FuturaPT-Book'; }
    @media  (min-width: 48em) {
      .paragraphs-item-button .field--name-field-button-title .field__item {
        font-size: 1.2rem; } }

.paragraphs-item-button .group-front:hover {
  background: rgba(133, 163, 18, 0.8);
  transition: all 0.2s ease-in; }

@media  (min-width: 64em) {
  .field--name-field-buttonbar-buttons > .field__items > .field__item .paragraphs-item-button .group-front:hover .field--name-field-button-subtitle {
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    transition: all 0.4s ease-in;
    opacity: 1;
    top: 0; } }

@media  (min-width: 64em) {
  .paragraphs-item-button .group-front:hover .field--name-field-button-title {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
    transition: all 0.4s ease-in;
    top: 40%; } }

.field--name-field-buttonbar-buttons > .field__items > .field__item {
  margin-bottom: 0;
  padding: 1px; }
  @media  (min-width: 64em) {
    .field--name-field-buttonbar-buttons > .field__items > .field__item {
      margin-bottom: 40px; } }

@media (max-width: 1023px) {
  .group-front {
    height: 60%;
    top: 40%;
    position: absolute;
    background: rgba(133, 163, 18, 0.8); }
  .field--name-field-buttonbar-buttons > .field__items > .field__item .field--name-field-button-subtitle {
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 1; } }

@media (min-width: 1030px) {
  .paragraphs-item-button .field--name-field-button-title .field__item {
    font-size: 1.4rem; } }

.paragraphs-item-testimonials {
  margin-top: 40px; }

.field--name-field-testimonials-title, .field--name-field-testimonials-intro {
  text-align: center; }

.field--name-field-testimonials-title {
  font-size: 1.5rem;
  padding: 20px 0;
  font-family: 'FuturaPT-Bold'; }

.paragraphs-items-field-testimonials {
  width: 70%;
  margin: 0 auto;
  margin-top: 40px; }
  .paragraphs-items-field-testimonials .field--name-field-testimonials > .field__items > .field__item {
    float: left;
    width: 100%;
    position: relative; }
    .paragraphs-items-field-testimonials .field--name-field-testimonials > .field__items > .field__item .field--name-field-testimonial-image {
      width: 100%;
      text-align: center; }
      @media  (min-width: 48em) {
        .paragraphs-items-field-testimonials .field--name-field-testimonials > .field__items > .field__item .field--name-field-testimonial-image {
          width: 25%;
          float: left;
          text-align: right; } }
      .paragraphs-items-field-testimonials .field--name-field-testimonials > .field__items > .field__item .field--name-field-testimonial-image > .field__items > .field__item {
        border-radius: 50%;
        overflow: hidden;
        width: 100px;
        height: 100px;
        float: none;
        margin: 20px auto; }
        @media  (min-width: 48em) {
          .paragraphs-items-field-testimonials .field--name-field-testimonials > .field__items > .field__item .field--name-field-testimonial-image > .field__items > .field__item {
            float: right;
            margin: auto; } }
      .paragraphs-items-field-testimonials .field--name-field-testimonials > .field__items > .field__item .field--name-field-testimonial-image img {
        display: block;
        min-width: 100%;
        min-height: 100%; }
    .paragraphs-items-field-testimonials .field--name-field-testimonials > .field__items > .field__item .field--name-field-testimonial-intro, .paragraphs-items-field-testimonials .field--name-field-testimonials > .field__items > .field__item .field--name-field-testimonial-quote {
      width: 100%; }
      @media  (min-width: 48em) {
        .paragraphs-items-field-testimonials .field--name-field-testimonials > .field__items > .field__item .field--name-field-testimonial-intro, .paragraphs-items-field-testimonials .field--name-field-testimonials > .field__items > .field__item .field--name-field-testimonial-quote {
          width: 60%;
          margin-left: 30%; } }
    .paragraphs-items-field-testimonials .field--name-field-testimonials > .field__items > .field__item .field--name-field-testimonial-quote p {
      font-size: 1rem;
      font-style: italic; }
    .paragraphs-items-field-testimonials .field--name-field-testimonials > .field__items > .field__item .field--name-field-testimonial-intro {
      padding-bottom: 20px;
      font-size: 1.2rem; }

.l-region--header-extra {
  display: none !important;
  /*Turn on off*/ }

header::-moz-selection, .label::-moz-selection {
  background: transparent; }

header::selection, .label::selection {
  background: transparent; }

.l-region--header-extra {
  display: none; }
  @media  (min-width: 64em) {
    .l-region--header-extra {
      margin-top: 24px;
      float: left;
      display: block; } }
  .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links .checkbox, .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links .block__title {
    display: none; }
  .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links .label, .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links .social {
    background-size: contain;
    background-repeat: no-repeat; }
  .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links .social {
    width: 40px;
    height: 40px;
    margin-bottom: 0; }
  .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links .label {
    width: 20px;
    height: 20px;
    margin-top: 10px;
    float: left;
    background-image: url('../svg/share.svg');
    text-indent: -9999px;
    cursor: pointer;
    margin-left: 10px; }
    @media  (min-width: 64em) {
      .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links .label {
        margin-left: 30px; } }
  .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links .external-links {
    float: left;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.3s, opacity 0.3s linear;
    margin-left: 10px; }
    @media  (min-width: 64em) {
      .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links .external-links {
        margin-left: 30px; } }
  .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links .facebook {
    background-image: url('../svg/fb2.svg'); }
  .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links .twitter {
    background-image: url('../svg/twitter.svg'); }
  .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links .googleplus {
    background-image: url('../svg/googleplus.svg'); }
  .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links .pinterest {
    background-image: url('../svg/pin.svg'); }
  @media  (min-width: 64em) {
    .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links li {
      cursor: pointer;
      float: left; }
      .l-region--header-extra .block--greenpan-sitewide-greenpan-sharing-links li a {
        text-indent: -999em;
        overflow: hidden;
        text-align: left; } }

.l-header .shrink .l-region--header-extra {
  margin-top: 6px; }

@media (min-width: 1025px) {
  /*.l-region--navigation {
    width: 45%;
    float: left;
  }*/
  .l-region--header-extra {
    margin-top: 24px;
    float: left;
    display: block; }
    .l-region--header-extra .checkbox:checked ~ .external-links {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s; }
    .l-region--header-extra li {
      cursor: pointer;
      float: left; }
      .l-region--header-extra li a {
        text-indent: -999em;
        overflow: hidden;
        text-align: left; } }

.section-langselect {
  background-color: #f0f0f0; }
  .section-langselect .l-main {
    background-image: url(../img/map.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center; }
  .section-langselect ul.language-select {
    overflow: hidden;
    *zoom: 1;
    width: 80%;
    margin: 0 auto;
    overflow: visible; }
    @media  (min-width: 26em) {
      .section-langselect ul.language-select {
        width: 60%; } }
    @media  (min-width: 48em) {
      .section-langselect ul.language-select {
        width: 85%;
        padding-top: 50px; } }
  .section-langselect li.continent {
    width: 100%;
    float: left;
    color: #333;
    font-size: 1.3rem;
    font-family: FuturaPT-Bold;
    margin-bottom: 45px; }
    @media  (min-width: 48em) {
      .section-langselect li.continent {
        width: 50%;
        padding: 0 15px; } }
    @media  (min-width: 64em) {
      .section-langselect li.continent {
        width: 25%;
        padding: 0; } }
    .section-langselect li.continent:last-of-type {
      margin-bottom: 0; }
  .section-langselect ul.continent {
    margin-top: 30px; }
  .section-langselect li.country {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
    font-size: 1rem;
    font-family: FuturaPT-Light;
    cursor: pointer;
    position: relative;
    border-bottom: 2px solid transparent; }
    .section-langselect li.country.current {
      color: red; }
    @media  (min-width: 26em) {
      .section-langselect li.country {
        width: 50%; } }
    @media  (min-width: 64em) {
      .section-langselect li.country {
        width: 100%; }
        .section-langselect li.country:hover {
          transition: all 1.2s linear; } }
  .section-langselect .item-list {
    display: inline; }
  .section-langselect ul.country {
    display: inline-block;
    float: right; }
    @media  (min-width: 26em) {
      .section-langselect ul.country {
        position: absolute;
        right: -100%;
        top: 0; } }
    @media  (min-width: 64em) {
      .section-langselect ul.country {
        transition: visibility 0s linear 0.3s, opacity 0.3s linear;
        visibility: hidden;
        opacity: 0;
        right: 20%;
        padding: 15px;
        background-color: #85a312;
        width: auto;
        top: -15px;
        z-index: 99;
        border-radius: 2px;
        box-shadow: 0px 12px 19px -10px rgba(0, 0, 0, 0.75); } }
    .section-langselect ul.country a {
      color: #85a312; }
      @media  (min-width: 64em) {
        .section-langselect ul.country a {
          color: #fff; } }
      .section-langselect ul.country a:hover {
        font-weight: bold; }
  .section-langselect li.country:hover ul.country {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s; }

.taxonomy-term--collection .field--name-field-coll-image img {
  width: 100%; }

@media (min-width: 1025px) {
  .nav-burger.label {
    display: none; } }

@media (max-width: 1315px) {
  .sticker {
    margin-left: 15px; } }

@media (max-width: 1024px) {
  .custom-menu-toggle {
    display: block; }
  .sticker {
    width: 160px;
    margin-left: 15px;
    z-index: 80;
    position: relative; } }

.view-collections .view-filters.opened .views-exposed-widgets {
  max-height: 900px;
  transition: all 0.5s;
  margin-top: 30px;
  opacity: 1;
  padding-bottom: 30px; }
  .view-collections .view-filters.opened .views-exposed-widgets label.option {
    margin-bottom: 0; }
/*# sourceMappingURL=greenpan15.no-query.css.map */